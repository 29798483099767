import { Chart, ChartTypeRegistry, TooltipModel } from "chart.js";
import moment from "moment";

import { GenericType } from "../../../types";
import {
  ChartData as ChartDataType,
  ChartTimeSeriesDataSet,
} from "../../../types/charts";

import {
  createTooltipHeader,
  getOrCreateTooltipDiv,
  getTooltipBodyValueDisplay,
} from "./helpers";

const buildTooltipBody = (
  tooltip: TooltipModel<keyof ChartTypeRegistry>,
  data: ChartDataType,
  hideEmptyValues: boolean = false,
): HTMLElement => {
  const dataIndex = tooltip.dataPoints?.[0]?.dataIndex;
  const tableBody = document.createElement("div");

  data?.datasets?.forEach((dataset: ChartTimeSeriesDataSet) => {
    const value = dataset.data[dataIndex]?.y;

    if (hideEmptyValues && !value) {
      return;
    }

    const valueUnit = dataset.unit;

    const content = getTooltipBodyValueDisplay(dataset, value, valueUnit);
    tableBody.appendChild(content);
  });
  return tableBody;
};
const setTooltipPosition = (
  tooltipEl: HTMLElement,
  tooltip: TooltipModel<keyof ChartTypeRegistry>,
  chart: Chart,
): void => {
  // Positioning the tooltip
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  const left = positionX + tooltip.caretX;
  const halfWidth = chart.canvas.width / 2;

  if (left < halfWidth) {
    tooltipEl.style.left = `${left - 20 + tooltipEl.getBoundingClientRect().width}px`;
  } else {
    tooltipEl.style.left = `${left + 20 - tooltipEl.getBoundingClientRect().width}px`;
  }

  tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
  // tooltipEl.style.top = `-${tooltipEl.getBoundingClientRect().height / 2}px`;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = "1";
  tooltipEl.style.visibility = "visible";
  tooltipEl.style.position = "absolute"; // Ensure absolute positioning
  tooltipEl.style.zIndex = "2000"; // Ensure it stays on top
};

export const defaultTimeSeriesTooltip = (
  context: {
    chart: Chart;
    tooltip: TooltipModel<keyof ChartTypeRegistry>;
  },
  data: ChartDataType,
  palette: GenericType,
  titleFormat: string = "YYYY/MM/DD",
  hideEmptyValues: boolean = false,
): void => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltipDiv(chart, palette);
  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0";
    tooltipEl.style.visibility = "hidden";
    return;
  }

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  if (tooltip.body) {
    let title = undefined;
    if (Array.isArray(tooltip.title)) {
      title = tooltip.title[0];
    }

    const header = createTooltipHeader();
    header.textContent = moment(parseInt(`${title}`)).format(titleFormat);

    const tableBody = buildTooltipBody(tooltip, data, hideEmptyValues);

    const tooltipRoot = tooltipEl.querySelector("div");

    // Remove old children
    while (tooltipRoot?.firstChild) {
      tooltipRoot?.firstChild?.remove();
    }

    // Add new children
    tooltipRoot?.appendChild(header);
    // tableRoot.appendChild(createTooltipDividerEl(contrastText));
    tooltipRoot?.appendChild(tableBody);
  }
  setTooltipPosition(tooltipEl, tooltip, chart);
};
