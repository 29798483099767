import { GenericType } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = GenericType[];

type ResponseData = { data: GenericType[] };

export const getProjectEvents: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/events/byfilter/`,
  method: "POST",
  data,
});
