/* eslint-disable prettier/prettier */
import { FC, useEffect, useMemo, useState } from "react";
import {
  ApexChart,
  Box,
  Divider,
  getEsgianTheme,
  Typography,
} from "@esgian/esgianui";

import { convertToYearsAndMonths } from "../../helper/chart";
import { chartFillerDates } from "../../helper/datesCalculations";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import { GenericType, THEME, ThemeModeEnum } from "../../types";

type Prop = {
  tempData: GenericType[];
};
export const ContractChart: FC<Prop> = ({ tempData }) => {
  const themeMode = useSelector(getThemeMode);
  const theme = getEsgianTheme(themeMode, THEME);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<GenericType | null>(
    null,
  );
  const [selectedItem, setSelectedItem] = useState<GenericType | null>(null);
  const [maxYAxisLeadTime, setMaxYAxisLeadTime] = useState(6);
  const [maxYAxisTrailing, setMaxYAxisTrailing] = useState(4);
  const data = chartFillerDates(tempData);

  const categories = useMemo(() => {
    return data.map((item) => item.date);
  }, [data]);

  const averageLeadTimeSeriesData = useMemo(() => {
    return data.map((item) =>
      item.numberOfContracts > 0 ? item.averageLeadTime : null,
    );
  }, [data]);

  const trailing12MonthsSeriesData = useMemo(() => {
    return data.map((item) => item.trailing12Months);
  }, [data]);

  useEffect(() => {
    if (
      Array.isArray(averageLeadTimeSeriesData) &&
      averageLeadTimeSeriesData.length > 0
    ) {
      const maxLeadTime = Math.max(
        0,
        ...averageLeadTimeSeriesData.filter(
          (item: number | null): item is number =>
            item !== null && item !== undefined,
        ),
      );
      setMaxYAxisLeadTime(maxLeadTime + 1);
    }
  }, [averageLeadTimeSeriesData]);

  useEffect(() => {
    if (
      Array.isArray(trailing12MonthsSeriesData) &&
      trailing12MonthsSeriesData.length > 0
    ) {
      const maxTrailing = Math.max(
        0,
        ...trailing12MonthsSeriesData.filter(
          (item: number | null): item is number =>
            item !== null && item !== undefined,
        ),
      );
      setMaxYAxisTrailing(maxTrailing + 1);
    }
  }, [trailing12MonthsSeriesData]);
  // Calculate the maximum value for the Y-axis, aligned to the tick intervals.
  // Explanation:
  // 1. / tickCount: Divide the scaled maximum by the number of ticks (tickCount) to determine the size of each tick interval.
  // 2. Math.ceil(...): Round up to the next whole number to ensure the maximum value is evenly divisible by the tick interval.
  // 3. * tickCount: Multiply back by the number of ticks to get the final aligned maximum value for the Y-axis.
  // This ensures the Y-axis ticks are evenly spaced and all data points fit cleanly within the chart.
  const tickCount = 5; // Number of ticks
  const maxYAxisLeadTimeAligned =
    Math.ceil(maxYAxisLeadTime / tickCount) * tickCount;
  const maxYAxisTrailingAligned =
    Math.ceil(maxYAxisTrailing / tickCount) * tickCount;

  const {
    palette: {
      mode,
      charts: { background },
    },
  } = theme;

  const options: GenericType = {
    chart: {
      height: 750,
      type: "mixed",
      zoom: {
        enabled: false,
      },
      background:
        themeMode === ThemeModeEnum.Dark
          ? theme.palette.common.black
          : background,
      events: {
        click: (event: GenericType) => {
          const rel = event.target.getAttribute("rel");
          if (!rel) {
            setTooltipVisible(false);
            setTooltipPosition(null);
            setSelectedItem(null);
          }
        },
      },
    },
    theme: {
      mode: mode,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories,
    },
    yaxis: [
      {
        tickAmount: tickCount,
        min: 0,
        max: maxYAxisTrailingAligned,
        labels: {
          formatter: (value: string) => {
            return parseInt(value, 10);
          },
          style: {
            colors:
              themeMode === ThemeModeEnum.Dark
                ? theme.palette.common.white
                : theme.palette.common.black,
          },
        },
        title: {
          text: "Trailing 12 Months",
        },
      },
      {
        opposite: true,
        tickAmount: tickCount,
        min: 0,
        max: maxYAxisLeadTimeAligned,
        labels: {
          formatter: (value: number) => parseFloat(value.toFixed(1)),
          style: {
            colors:
              themeMode === ThemeModeEnum.Dark
                ? theme.palette.common.white
                : theme.palette.common.black,
          },
        },
        title: {
          text: "Average Lead Time",
        },
      },
    ],

    labels: categories,
    markers: {
      size: [7, 8],
      strokeWidth: 0,
      onClick: function (e: GenericType) {
        const rel = e.target.getAttribute("rel");
        const index = e.target.getAttribute("index");
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const offsetX = 550;
        const offsetY = 150;
        let x = e.x;
        let y = e.y;
        if (x + offsetX > viewportWidth) {
          x -= offsetX;
        } else if (x - offsetX < 0) {
          x = offsetX;
        }
        if (y + offsetY > viewportHeight) {
          y -= offsetY;
        } else if (x - offsetY < 0) {
          y = offsetY;
        }
        setSelectedItem({ data: data[rel], index });
        setTooltipPosition({ x, y });
        setTooltipVisible(true);
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",

      labels: {
        colors:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.white
            : theme.palette.common.black,
        useSeriesColors: false,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const series = [
    {
      name: "Average Lead Time",
      type: "scatter",
      color: "rgb(0, 100, 255)",
      data: averageLeadTimeSeriesData,
    },
    {
      name: "Trailing 12 Months",
      type: "line",
      color: "rgb(201, 78, 221)",
      data: trailing12MonthsSeriesData,
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          "& .apexcharts-menu": {
            background:
              themeMode === ThemeModeEnum.Dark
                ? theme.palette.common.black
                : theme.palette.common.white,
            "& .apexcharts-menu-item": {
              ":hover": {
                background: "#eee",
                color: theme.palette.common.black,
              },
            },
          },
        }}
      >
        <ApexChart
          options={options || {}}
          data={series}
          height={750}
          type="line"
        />
      </Box>
      {tooltipVisible && selectedItem ? (
        <Box
          sx={{
            position: "absolute",
            left: tooltipPosition?.x,
            top: tooltipPosition?.y,
            background:
              themeMode === ThemeModeEnum.Dark
                ? theme.palette.common.black
                : theme.palette.common.white,
            color:
              themeMode === ThemeModeEnum.Dark
                ? theme.palette.common.white
                : theme.palette.common.black,
            fontSize: 12,
            borderRadius: 2,
          }}
        >
          <Box sx={{ px: 3, py: 2 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              {selectedItem.data.date}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography variant="subtitle2" sx={{ mr: 2 }}>
                Number of contracts:
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                {selectedItem.data.numberOfContracts}
              </Typography>
            </Box>
            {Number(selectedItem.index) === 1 ? (
              <Box sx={{ display: "flex" }}>
                <Typography variant="subtitle2" sx={{ mr: 2 }}>
                  Trailing 12 months:
                </Typography>
                <Box sx={{ display: "flex", fontWeight: 600 }}>
                  <Typography variant="subtitle2">
                    {
                      convertToYearsAndMonths(
                        selectedItem.data.trailing12Months,
                      ).years
                    }
                  </Typography>
                  <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
                    years
                  </Typography>
                  <Typography variant="subtitle2" sx={{ ml: 1 }}>
                    {
                      convertToYearsAndMonths(
                        selectedItem.data.trailing12Months,
                      ).months
                    }
                  </Typography>
                  <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
                    months
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: "flex" }}>
                <Typography variant="subtitle2" sx={{ mr: 2 }}>
                  Average lead time:
                </Typography>
                <Box sx={{ display: "flex", fontWeight: 600 }}>
                  <Typography variant="subtitle2">
                    {
                      convertToYearsAndMonths(selectedItem.data.averageLeadTime)
                        .years
                    }
                  </Typography>
                  <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
                    years
                  </Typography>
                  <Typography variant="subtitle2" sx={{ ml: 1 }}>
                    {
                      convertToYearsAndMonths(selectedItem.data.averageLeadTime)
                        .months
                    }
                  </Typography>
                  <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
                    months
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Divider />
          <Box sx={{ px: 2, py: 1, width: 550 }}>
            <Box
              sx={{
                display: "flex",
                borderBottom: 1,
                fontWeight: 600,
                pb: 1,
              }}
            >
              <Box sx={{ width: "30%" }}>Wind farm name</Box>
              <Box sx={{ width: "30%" }}>Country</Box>
              {selectedItem.data.averageLeadTimeProjects?.[0]?.turbineModel !==
              null ? (
                <Box sx={{ width: "30%" }}>Model</Box>
              ) : null}
              {selectedItem.data.averageLeadTimeProjects?.[0]?.equipmentType !==
              null ? (
                <Box
                  sx={{
                    width:
                      selectedItem.data.averageLeadTimeProjects?.[0]
                        ?.vesselName !== null
                        ? "20%"
                        : "30",
                  }}
                >
                  Equipment Type
                </Box>
              ) : null}
              {selectedItem.data.averageLeadTimeProjects?.[0]?.vesselName !==
              null ? (
                <Box sx={{ width: "20%" }}>Vessel</Box>
              ) : null}
            </Box>
            {selectedItem.data.averageLeadTimeProjects?.map(
              (item: GenericType) => (
                <Box
                  key={item.name}
                  sx={{
                    display: "flex",
                    py: 1,
                  }}
                >
                  <Box sx={{ width: "30%" }}>{item.windFarmName}</Box>
                  <Box sx={{ width: "30%" }}>{item.country}</Box>
                  {selectedItem.data.averageLeadTimeProjects?.[0]
                    .turbineModel !== null ? (
                    <Box sx={{ width: "30%" }}>{item.turbineModel}</Box>
                  ) : null}
                  {selectedItem.data.averageLeadTimeProjects?.[0]
                    .equipmentType !== null ? (
                    <Box
                      sx={{
                        width:
                          selectedItem.data.averageLeadTimeProjects?.[0]
                            .vesselName !== null
                            ? "20%"
                            : "30",
                      }}
                    >
                      {item.equipmentType}
                    </Box>
                  ) : null}
                  {selectedItem.data.averageLeadTimeProjects?.[0].vesselName !==
                  null ? (
                    <Box sx={{ width: "20%" }}>{item.vesselName}</Box>
                  ) : null}
                </Box>
              ),
            )}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
