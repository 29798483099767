import { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  getEsgianTheme,
  Grid,
  Radio,
  RadioGroup,
} from "@esgian/esgianui";

import {
  calculateFilterCount,
  filterDataBasedOnDynamicKeys,
} from "../../helper/fllter";
import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getCountries,
  getFilterModal,
  getSearchText,
  getThemeMode,
  getViewTab,
} from "../../store/selector/common";
import {
  getEquipmentContractChart,
  getEquipmentContractFilterOptions,
  getEquipmentContractList,
  getEquipmentContractSelectedFilter,
  getEquipmentIsLoading,
} from "../../store/selector/contract";
import {
  setFilterModal,
  setSelectedFactoryId,
  setSelectedFloatingTechnologyId,
  setSelectedProjectId,
} from "../../store/slice/common";
import {
  fetchEquipmentContractChart,
  fetchEquipmentContractList,
  setEquipmentContractSelectedFilter,
} from "../../store/slice/contract";
import {
  DataTableMetadata,
  EquipmentContractTabEnum,
  FloatingEquipment,
  GenericType,
  THEME,
  ThemeModeEnum,
  UnifiedEquipmentContract,
  ViewTabEnum,
} from "../../types";
import { ContractChart } from "../ContractChart";
import { EquipmentContractList } from "../EquipmentContractList";
import { FilterBtn } from "../FilterBtn";
import { Loading } from "../Loading";
import { FilterModal } from "../Modal/FilterModal";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);

  return {
    firstColumn: {
      color:
        themeMode === ThemeModeEnum.Dark
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
  };
};

export const EquipmentContract = () => {
  const dispatch = useDispatch();

  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);

  const viewTab = useSelector(getViewTab);
  const searchText = useSelector(getSearchText);
  const isLoading = useSelector(getEquipmentIsLoading);
  const selectedFilter = useSelector(getEquipmentContractSelectedFilter);
  const isFilterModalOpen = useSelector(getFilterModal);
  const equipmentList = useSelector(getEquipmentContractList);
  const options = useSelector(getEquipmentContractFilterOptions);
  const countries = useSelector(getCountries);
  const chartData = useSelector(getEquipmentContractChart);
  const [tab, setTab] = useState<EquipmentContractTabEnum>(
    EquipmentContractTabEnum.Turbines,
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEquipmentContractSelectedFilter({ tab, filterValues: {} }));
    setTab(event.target.value as EquipmentContractTabEnum);
  };

  const data = useMemo(() => {
    let list = equipmentList?.[tab] as UnifiedEquipmentContract[];
    const filter = selectedFilter?.[tab] || [];
    if (!list || !list.length) return list;
    list = filterDataBasedOnDynamicKeys(
      list as GenericType[],
      filter,
    ) as UnifiedEquipmentContract[];
    if (searchText?.length && list?.length) {
      list = list?.filter((item: GenericType) =>
        isMatch(item, [searchText]),
      ) as UnifiedEquipmentContract[];
    }
    return list || [];
  }, [equipmentList, tab, searchText, selectedFilter]);

  const columns = useMemo(() => {
    return {
      turbines: [
        {
          name: "contractAwardDate",
          label: "Contract award date",
          options: {
            customBodyRender: (value: string) => {
              return <Box sx={classes.firstColumn}>{value}</Box>;
            },
          },
        },
        { name: "supplier", label: "Supplier" },
        {
          name: "windFarm",
          label: "Wind farm",
          options: {
            customBodyRender: (windFarmName: string) => {
              const rowData = data.find((row) => row.windFarm === windFarmName);
              let windFarmId = null;
              if (rowData) {
                windFarmId = rowData.windFarmId;
              }
              if (
                windFarmId &&
                windFarmId.toString().includes(",") &&
                windFarmName &&
                windFarmName.includes(",")
              ) {
                const windFarmNames = windFarmName.split(", ");
                const windFarmIds = windFarmId.toString().split(", ");
                return (
                  <>
                    {windFarmNames.map((name, index) => (
                      <Box
                        sx={{ color: "#0E9EFF", cursor: "pointer" }}
                        key={index}
                        onClick={() => {
                          dispatch(
                            setSelectedProjectId(Number(windFarmIds[index])),
                          );
                        }}
                      >
                        {name}
                      </Box>
                    ))}
                  </>
                );
              }
              return (
                <Box sx={{ color: "#0E9EFF", cursor: "pointer" }}>
                  {windFarmName}
                </Box>
              );
            },
          },
        },
        { name: "country", label: "Country" },
        { name: "numberOfTurbines", label: "Number of turbines" },
        {
          name: "turbineModel",
          label: "Turbine model",
          options: {
            customBodyRender: (value: string) => {
              return (
                <Box sx={{ color: "#0E9EFF", cursor: "pointer" }}>{value}</Box>
              );
            },
          },
        },
        { name: "contractStatus", label: "Contract status" },
      ],
      foundations: [
        {
          name: "contractAwardDate",
          label: "Contract award date",
          options: {
            customBodyRender: (value: string) => {
              return <Box sx={classes.firstColumn}>{value}</Box>;
            },
          },
        },
        { name: "supplier", label: "Supplier" },
        {
          name: "windFarm",
          label: "Wind farm",
          options: {
            customBodyRender: (windFarmName: string) => {
              const rowData = data.find((row) => row.windFarm === windFarmName);
              let windFarmId = null;
              if (rowData) {
                windFarmId = rowData.windFarmId;
              }
              if (
                windFarmId &&
                windFarmId.toString().includes(",") &&
                windFarmName &&
                windFarmName.includes(",")
              ) {
                const windFarmNames = windFarmName.split(", ");
                const windFarmIds = windFarmId.toString().split(", ");
                return (
                  <>
                    {windFarmNames.map((name, index) => (
                      <Box
                        sx={{ color: "#0E9EFF", cursor: "pointer" }}
                        key={index}
                        onClick={() => {
                          dispatch(
                            setSelectedProjectId(Number(windFarmIds[index])),
                          );
                        }}
                      >
                        {name}
                      </Box>
                    ))}
                  </>
                );
              }
              return (
                <Box sx={{ color: "#0E9EFF", cursor: "pointer" }}>
                  {windFarmName}
                </Box>
              );
            },
          },
        },
        { name: "country", label: "Country" },
        { name: "numberOfFoundations", label: "Number of foundations" },
        { name: "foundationType", label: "Equipment type" },
        { name: "contractStatus", label: "Contract status" },
        {
          name: "factory",
          label: "Factory",
          options: {
            customBodyRender: (value: GenericType[]) => {
              return value.map((factory) => (
                <Box
                  onClick={() => {
                    dispatch(setSelectedFactoryId(factory.id));
                  }}
                  key={factory.id}
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                >
                  {factory.name}
                </Box>
              ));
            },
          },
        },
        { name: "foundationMass", label: "Foundation mass" },
        { name: "foundationLength", label: "Foundation length" },
        { name: "foundationDiameter", label: "Foundation diameter" },
        { name: "foundationThickness", label: "Foundation thickness" },
      ],
      cables: [
        {
          name: "contractAwardDate",
          label: "Contract award date",
          options: {
            customBodyRender: (value: string) => {
              return <Box sx={classes.firstColumn}>{value}</Box>;
            },
          },
        },
        { name: "supplier", label: "Supplier" },
        {
          name: "windFarm",
          label: "Wind farm",
          options: {
            customBodyRender: (windFarmName: string) => {
              const rowData = data.find((row) => row.windFarm === windFarmName);
              let windFarmId = null;
              if (rowData) {
                windFarmId = rowData.windFarmId;
              }
              if (
                windFarmId &&
                windFarmId.toString().includes(",") &&
                windFarmName &&
                windFarmName.includes(",")
              ) {
                const windFarmNames = windFarmName.split(", ");
                const windFarmIds = windFarmId.toString().split(", ");
                return (
                  <>
                    {windFarmNames.map((name, index) => (
                      <Box
                        sx={{ color: "#0E9EFF", cursor: "pointer" }}
                        key={index}
                        onClick={() => {
                          dispatch(
                            setSelectedProjectId(Number(windFarmIds[index])),
                          );
                        }}
                      >
                        {name}
                      </Box>
                    ))}
                  </>
                );
              }
              return (
                <Box sx={{ color: "#0E9EFF", cursor: "pointer" }}>
                  {windFarmName}
                </Box>
              );
            },
          },
        },
        { name: "country", label: "Country" },
        { name: "cableType", label: "Cable type" },
        { name: "contractStatus", label: "Contract status" },
        {
          name: "factory",
          label: "Factory",
          options: {
            customBodyRender: (value: GenericType[]) => {
              return value.map((v) => (
                <Box
                  onClick={() => {
                    dispatch(setSelectedFactoryId(v.id));
                  }}
                  key={v.id}
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                >
                  {v.name}
                </Box>
              ));
            },
          },
        },
        { name: "voltageKV", label: "Voltage (kV)" },
        { name: "outputCurrent", label: "Output current" },
        { name: "numberOfCables", label: "Number of cables" },
        { name: "cableLengthKM", label: "Cable length (km)" },
      ],
      substations: [
        {
          name: "contractAwardDate",
          label: "Contract award date",
          options: {
            customBodyRender: (value: string) => {
              return <Box sx={classes.firstColumn}>{value}</Box>;
            },
          },
        },
        { name: "supplier", label: "Supplier" },
        {
          name: "windFarm",
          label: "Wind farm",
          options: {
            customBodyRender: (windFarmName: string) => {
              const rowData = data.find((row) => row.windFarm === windFarmName);
              let windFarmId = null;
              if (rowData) {
                windFarmId = rowData.windFarmId;
              }
              if (
                windFarmId &&
                windFarmId.toString().includes(",") &&
                windFarmName &&
                windFarmName.includes(",")
              ) {
                const windFarmNames = windFarmName.split(", ");
                const windFarmIds = windFarmId.toString().split(", ");
                return (
                  <>
                    {windFarmNames.map((name, index) => (
                      <Box
                        sx={{ color: "#0E9EFF", cursor: "pointer" }}
                        key={index}
                        onClick={() => {
                          dispatch(
                            setSelectedProjectId(Number(windFarmIds[index])),
                          );
                        }}
                      >
                        {name}
                      </Box>
                    ))}
                  </>
                );
              }
              return (
                <Box sx={{ color: "#0E9EFF", cursor: "pointer" }}>
                  {windFarmName}
                </Box>
              );
            },
          },
        },
        { name: "country", label: "Country" },
        { name: "substation", label: "Substation" },
        { name: "components", label: "Components" },
        { name: "contractStatus", label: "Contract status" },
        {
          name: "factory",
          label: "Factory",
          options: {
            customBodyRender: (value: GenericType[]) => {
              return value.map((v) => (
                <Box
                  onClick={() => {
                    dispatch(setSelectedFactoryId(v.id));
                  }}
                  key={v.id}
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                >
                  {v.name}
                </Box>
              ));
            },
          },
        },
        { name: "topsidesMassMT", label: "Topsides mass (mt)" },
        { name: "topsidesHeightM", label: "Topsides height (m)" },
        { name: "topsidesWidthM", label: "Topsides width (m)" },
        { name: "topsidesLengthM", label: "Topsides length (m)" },
        { name: "foundationType", label: "Foundation type" },
        { name: "foundationMassMT", label: "Foundation mass (mt)" },
        { name: "foundationHeightM", label: "Foundation height (m)" },
        { name: "foundationWidthM", label: "Foundation width (m)" },
        { name: "foundationLengthM", label: "Foundation length (m)" },
        { name: "totalMassMT", label: "Total mass (mt)" },
        { name: "numberOfJacketLegs", label: "Number of jacket legs" },
        { name: "outputCurrent", label: "Output current" },
        { name: "voltageInKV", label: "Voltage in (kV)" },
        { name: "voltageOutKV", label: "Voltage out (kV)" },
      ],
      towers: [
        {
          name: "contractAwardDate",
          label: "Contract award date",
          options: {
            customBodyRender: (value: string) => {
              return <Box sx={classes.firstColumn}>{value}</Box>;
            },
          },
        },
        { name: "supplier", label: "Supplier" },
        {
          name: "windFarm",
          label: "Wind farm",
          options: {
            customBodyRender: (windFarmName: string) => {
              const rowData = data.find((row) => row.windFarm === windFarmName);
              let windFarmId = null;
              if (rowData) {
                windFarmId = rowData.windFarmId;
              }
              if (
                windFarmId &&
                windFarmId.toString().includes(",") &&
                windFarmName &&
                windFarmName.includes(",")
              ) {
                const windFarmNames = windFarmName.split(", ");
                const windFarmIds = windFarmId.toString().split(", ");
                return (
                  <>
                    {windFarmNames.map((name, index) => (
                      <Box
                        sx={{ color: "#0E9EFF", cursor: "pointer" }}
                        key={index}
                        onClick={() => {
                          dispatch(
                            setSelectedProjectId(Number(windFarmIds[index])),
                          );
                        }}
                      >
                        {name}
                      </Box>
                    ))}
                  </>
                );
              }
              return (
                <Box sx={{ color: "#0E9EFF", cursor: "pointer" }}>
                  {windFarmName}
                </Box>
              );
            },
          },
        },
        { name: "country", label: "Country" },
        { name: "contractStatus", label: "Contract status" },
        {
          name: "factory",
          label: "Factory",
          options: {
            customBodyRender: (value: GenericType[]) => {
              return value.map((v) => (
                <Box
                  onClick={() => {
                    dispatch(setSelectedFactoryId(v.id));
                  }}
                  key={v.id}
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                >
                  {v.name}
                </Box>
              ));
            },
          },
        },
        { name: "numberOfTowers", label: "Number of towers" },
        { name: "numberOfSections", label: "Number of sections" },
        { name: "sectionHeight", label: "Section height" },
        { name: "towerHeight", label: "Tower height" },
        { name: "towerMass", label: "Tower mass" },
      ],
      "floating-equipment": [
        {
          name: "contractAwardDate",
          label: "Contract award date",
          options: {
            customBodyRender: (value: string) => {
              return <Box sx={classes.firstColumn}>{value}</Box>;
            },
          },
        },
        { name: "supplier", label: "Supplier" },
        {
          name: "windFarm",
          label: "Wind farm",
          options: {
            customBodyRender: (windFarmName: string) => {
              const rowData = data.find((row) => row.windFarm === windFarmName);
              let windFarmId = null;
              if (rowData) {
                windFarmId = rowData.windFarmId;
              }
              if (
                windFarmId &&
                windFarmId.toString().includes(",") &&
                windFarmName &&
                windFarmName.includes(",")
              ) {
                const windFarmNames = windFarmName.split(", ");
                const windFarmIds = windFarmId.toString().split(", ");
                return (
                  <>
                    {windFarmNames.map((name, index) => (
                      <Box
                        sx={{ color: "#0E9EFF", cursor: "pointer" }}
                        key={index}
                        onClick={() => {
                          dispatch(
                            setSelectedProjectId(Number(windFarmIds[index])),
                          );
                        }}
                      >
                        {name}
                      </Box>
                    ))}
                  </>
                );
              }
              return (
                <Box sx={{ color: "#0E9EFF", cursor: "pointer" }}>
                  {windFarmName}
                </Box>
              );
            },
          },
        },
        { name: "country", label: "Country" },
        { name: "contractType", label: "Contract type" },
        { name: "equipmentType", label: "Equipment Type" },
        {
          name: "factory",
          label: "Factory",
          options: {
            customBodyRender: (value: GenericType[]) => {
              return value.map((v) => (
                <Box
                  onClick={() => {
                    dispatch(setSelectedFactoryId(v.id));
                  }}
                  key={v.id}
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                >
                  {v.name}
                </Box>
              ));
            },
          },
        },
        { name: "contractStatus", label: "Contract status" },
        {
          name: "technologyType",
          label: "Technology type",
          options: {
            customBodyRender: (value: string, tableMeta: DataTableMetadata) => {
              return (
                <Box
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                  onClick={() =>
                    dispatch(
                      setSelectedFloatingTechnologyId(
                        (data?.[tableMeta?.rowIndex] as FloatingEquipment)
                          .floatingTechnologyId,
                      ),
                    )
                  }
                >
                  {value}
                </Box>
              );
            },
          },
        },
      ],
    }[tab];
  }, [classes.firstColumn, data, dispatch, tab]);

  useEffect(() => {
    if (countries.length) {
      dispatch(fetchEquipmentContractList());
      dispatch(fetchEquipmentContractChart());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  useEffect(() => {
    if (
      viewTab === ViewTabEnum.Chart &&
      (tab === EquipmentContractTabEnum.Towers ||
        tab === EquipmentContractTabEnum.FloatingEquipment)
    ) {
      setTab(EquipmentContractTabEnum.Turbines);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewTab]);

  return (
    <Box sx={{ px: 2, maxWidth: "calc(100vw - 265px)" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <FormControl component="fieldset" sx={{ px: 2 }} fullWidth={true}>
          <Grid
            container={true}
            alignItems="center"
            spacing={2}
            fullWidth={true}
          >
            <Grid item={true}>
              <FormLabel id="view-radio-buttons">View by</FormLabel>
            </Grid>
            <Grid item={true}>
              <RadioGroup
                row={true}
                aria-labelledby="view-radio-buttons"
                name="view-radio-buttons"
                value={tab}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={EquipmentContractTabEnum.Turbines}
                  control={<Radio />}
                  label="Turbines"
                />
                <FormControlLabel
                  value={EquipmentContractTabEnum.Foundations}
                  control={<Radio />}
                  label="Foundations"
                />
                <FormControlLabel
                  value={EquipmentContractTabEnum.Cables}
                  control={<Radio />}
                  label="Cables"
                />
                <FormControlLabel
                  value={EquipmentContractTabEnum.Substations}
                  control={<Radio />}
                  label="Substations"
                />
                {viewTab === ViewTabEnum.List ? (
                  <>
                    <FormControlLabel
                      value={EquipmentContractTabEnum.Towers}
                      control={<Radio />}
                      label="Towers"
                    />
                    <FormControlLabel
                      value={EquipmentContractTabEnum.FloatingEquipment}
                      control={<Radio />}
                      label="Floating equipment"
                    />
                  </>
                ) : null}
              </RadioGroup>
            </Grid>
            {viewTab === ViewTabEnum.List ? (
              <Grid
                item={true}
                style={{
                  marginLeft: "auto",
                }}
              >
                <FilterBtn count={calculateFilterCount(selectedFilter)} />
              </Grid>
            ) : null}
          </Grid>
        </FormControl>
      </Box>
      {!isLoading ? (
        <>
          {viewTab === ViewTabEnum.List ? (
            <EquipmentContractList columns={columns} data={data} />
          ) : (
            <ContractChart tempData={chartData?.[tab] || []} />
          )}
          {options ? (
            <FilterModal
              open={isFilterModalOpen}
              handleClose={() => dispatch(setFilterModal(false))}
              selectedFilter={selectedFilter?.[tab] || {}}
              onChange={(filterValues) =>
                dispatch(
                  setEquipmentContractSelectedFilter({ filterValues, tab }),
                )
              }
              options={options?.[tab]}
            />
          ) : null}
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
