import { FC } from "react";
import { Modal, Stack, Typography } from "@esgian/esgianui";

type Prop = {
  modalOpen: boolean;
  setModalOpen: (selectedValue: boolean) => void;
};

export const PowerGenDisclaimerModal: FC<Prop> = ({
  modalOpen,
  setModalOpen,
}) => {
  return (
    <Modal
      handleClose={() => {
        setModalOpen(false);
      }}
      title="Disclaimer and Terms & Conditions"
      open={modalOpen}
      size="lg"
    >
      <Stack>
        <Typography variant="body2">
          The information and reports available on the Esgian AS’ Power
          Generation Module webpage are based exclusively on information
          acquired from public sources. Esgian AS processes this data as
          received and aggregates it to various timeframes and asset levels.
          Esgian AS strives to provide information on this website that is
          accurate, reliable, and complete. However, inaccuracies, omissions, or
          outdated content may occasionally occur. Esgian AS reserves the right
          to update, modify, or remove the information and services on this
          website at any time without prior notice. All content is provided “as
          is” and is subject to change without any warranty of any kind, whether
          express or implied.
        </Typography>
        <br />
        <Typography variant="body2">
          Esgian AS does not accept responsibility for errors made by third
          parties or for interruptions in data transmission caused by failures
          or outages in data processing systems. Esgian AS disclaims any
          liability for damages resulting from the temporary or permanent, full
          or partial unavailability of its website.
        </Typography>
        <br />
        <Typography variant="body2">
          Esgian AS shall not, under any circumstances, be held liable for any
          type of damages, including but not limited to direct, indirect,
          special, incidental, or consequential damages arising from the use of
          the information on this website. Your use of the website and these
          Terms of Use are governed by Norwegian law.
        </Typography>
        <br />
        <Typography variant="body2">
          The user shall not modify, reproduce, distribute, create derivative
          works of, or reverse engineer the Esgian Power Generation Module and
          its data without the prior written consent of Esgian AS.
        </Typography>
      </Stack>
    </Modal>
  );
};
