import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Table,
  Typography,
} from "@esgian/esgianui";

import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getRowsPerPage,
  getSearchText,
  getThemeMode,
} from "../../store/selector/common";
import { getSortingInfo } from "../../store/selector/powerOutput";
import { setRowsPerPage } from "../../store/slice/common";
import {
  setPowerGenTableSort,
  setPowerGenWindProject,
} from "../../store/slice/powerOutput";
import { GenericType, PowerOutputEnum, ROUTES_CONFIG } from "../../types";
import { Loading } from "../Loading";
import { PowerGenDisclaimerModal } from "../PowerGenDisclaimerModal";

type Prop = {
  tab: PowerOutputEnum;
  selectedView: string;
  setSelectedView: (selectedValue: string) => void;
  tableData: GenericType;
};

export const PowerOutputProjectListWindFarm: FC<Prop> = ({
  tab,
  selectedView,
  setSelectedView,
  tableData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sortingInfo = useSelector(getSortingInfo);
  const rowsPerPage = useSelector(getRowsPerPage);
  const themeMode = useSelector(getThemeMode);
  const searchText = useSelector(getSearchText);
  const isCapacity = selectedView === "Capacity";
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const title = useMemo(() => {
    return tab.toLocaleLowerCase().replace("-", " ") + "s";
  }, [tab]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText ?? "");
    }, 500); // Delay of 1 second

    return () => {
      clearTimeout(handler); // Cleanup on unmount or if `searchText` changes
    };
  }, [searchText]);

  const data = useMemo(() => {
    if (!tableData) return [];
    let list = [...Object.values(tableData)];
    if (debouncedSearchText?.length && list?.length) {
      list = list?.filter((item: GenericType) =>
        isMatch(item, [debouncedSearchText]),
      );
    }
    return list.map((item) => {
      const {
        capacityMw,
        accumulatedData: { capacityFactor, generationOutput },
      } = item;
      const accum = isCapacity ? capacityFactor : generationOutput;
      const belowAvgs: GenericType = {};
      Object.keys(capacityFactor).forEach((key) => {
        belowAvgs[key] = capacityFactor[key].value < 25;
      });
      return {
        ...accum,
        belowAvgs: belowAvgs,
        capacityMw: capacityMw,
        name: { name: item.name, id: item.id, sourceName: item.sourceName },
      };
    });
  }, [debouncedSearchText, isCapacity, tableData]);

  const renderTimeColumn = useCallback(
    (
      cellData: GenericType,
      project: GenericType,
      columnName: string,
    ): JSX.Element => {
      const value = cellData.value;
      const matchedProject = Object.values(data).find(
        (item) => item?.name === project,
      );
      const belowAvgValue = matchedProject?.belowAvgs?.[columnName];
      if (value) {
        return (
          <span
            style={{
              backgroundColor: belowAvgValue
                ? "rgba(210, 0, 0, 0.4)"
                : "transparent",
            }}
          >{`${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`}</span>
        );
      }
      return <div></div>;
    },
    [isCapacity, data],
  );

  const sortCompare = (order: "asc" | "desc") => {
    return (obj1: GenericType, obj2: GenericType) => {
      return (obj1.data.value - obj2.data.value) * (order === "asc" ? 1 : -1);
    };
  };

  const COLUMNS = useMemo(
    () => [
      {
        label: "Name",
        name: "name",
        options: {
          sortCompare: (order: "asc" | "desc") => {
            return (obj1: GenericType, obj2: GenericType) => {
              return (
                obj1.data.name.localeCompare(obj2.data.name) *
                (order === "asc" ? 1 : -1)
              );
            };
          },
          customBodyRender: (value: GenericType) => {
            if (!value) {
              return "N/A";
            }
            return (
              <Typography
                sx={{ cursor: "pointer" }}
                variant="caption"
                onClick={() => {
                  dispatch(
                    setPowerGenWindProject({
                      sourceName: value.sourceName,
                      name: value.name,
                      id: value.id,
                    }),
                  );
                  navigate(`${ROUTES_CONFIG.PowerOutputProfile.url}`);
                }}
              >
                {value.name}
              </Typography>
            );
          },
        },
      },
      {
        label: "Current installed capacity (MW)",
        name: "capacityMw",
      },
      {
        label: "1 week",
        name: `oneWeeks`,
        options: {
          customBodyRender: (cellData: GenericType, tableMeta: GenericType) => {
            return renderTimeColumn(cellData, tableMeta.rowData[0], "oneWeeks");
          },
          sortCompare: sortCompare,
        },
      },
      {
        label: "1 month",
        name: `oneMonths`,
        options: {
          customBodyRender: (cellData: GenericType, tableMeta: GenericType) => {
            return renderTimeColumn(
              cellData,
              tableMeta.rowData[0],
              "oneMonths",
            );
          },
          sortCompare: sortCompare,
        },
      },
      {
        label: "3 months",
        name: `threeMonths`,
        options: {
          customBodyRender: (cellData: GenericType, tableMeta: GenericType) => {
            return renderTimeColumn(
              cellData,
              tableMeta.rowData[0],
              "threeMonths",
            );
          },
          sortCompare: sortCompare,
        },
      },
      {
        label: "6 months",
        name: `sixMonths`,
        options: {
          customBodyRender: (cellData: GenericType, tableMeta: GenericType) => {
            return renderTimeColumn(
              cellData,
              tableMeta.rowData[0],
              "sixMonths",
            );
          },
          sortCompare: sortCompare,
        },
      },
      {
        label: "YTD",
        name: `ytd`,
        options: {
          customBodyRender: (cellData: GenericType, tableMeta: GenericType) => {
            return renderTimeColumn(cellData, tableMeta.rowData[0], "ytd");
          },
          sortCompare: sortCompare,
        },
      },
      {
        label: "1 year",
        name: `oneYears`,
        options: {
          customBodyRender: (cellData: GenericType, tableMeta: GenericType) => {
            return renderTimeColumn(cellData, tableMeta.rowData[0], "oneYears");
          },
          sortCompare: sortCompare,
        },
      },
      {
        label: "3 years",
        name: `threeYears`,
        options: {
          customBodyRender: (cellData: GenericType, tableMeta: GenericType) => {
            return renderTimeColumn(
              cellData,
              tableMeta.rowData[0],
              "threeYears",
            );
          },
          sortCompare: sortCompare,
        },
      },
    ],
    [renderTimeColumn, dispatch, navigate],
  );

  return (
    <Box sx={{ px: 2 }}>
      {data.length ? (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1" sx={{ py: 2 }}>
              {`Displaying ${data?.length} ${title}`}
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={5}
              alignItems="baseline"
            >
              <Stack spacing={1} direction="row" alignItems="baseline">
                <Typography variant="body2">View by</Typography>
                <FormControl disabled={false} size="small">
                  <Select
                    variant="outlined"
                    inputProps={{ sx: { pl: 1, pr: 1, pt: 0.5, pb: 0.5 } }}
                    value={selectedView}
                    onChange={({ target }: GenericType) =>
                      setSelectedView(target.value)
                    }
                    autoWidth="true"
                  >
                    <MenuItem value="Output">Power generation</MenuItem>
                    <MenuItem value="Capacity">Capacity factor</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Table
              app="wind"
              title={`${selectedView === "Output" ? "Power output" : "Capacity factor"} ${title} ${selectedView === "Output" ? "- GWh" : ""}`}
              columnDefs={[]}
              columns={COLUMNS}
              data={data || []}
              elevation={0}
              mode={themeMode}
              options={{
                sortOrder: sortingInfo,
                onDownload: (
                  buildHead: (columns: GenericType[]) => string,
                  buildBody: (data: GenericType[]) => string,
                  columns: GenericType[],
                  bodyData: GenericType[],
                ) => {
                  let dataFixed = [...bodyData];
                  dataFixed = dataFixed.map((d) => {
                    d.data[0] = d.data[0].name;
                    return d;
                  });
                  // Handle utf for norwegian letters
                  return "\uFEFF" + buildHead(columns) + buildBody(dataFixed);
                },
                download: true,
                search: false,
                downloadOptions: {
                  filename: `power_output_data.csv`,
                },
                expandableRows: false,
                filter: false,
                filterType: "dropdown",
                hideToolbarMargin: false,
                pagination: true,
                responsive: "vertical",
                rowsPerPage,
                onChangeRowsPerPage: (numberOfRows: number) => {
                  dispatch(setRowsPerPage(numberOfRows));
                },

                rowsPerPageOptions: [25, 50, 100],
                selectableRows: "none",
                onColumnSortChange: (name: string, direction: string) => {
                  dispatch(setPowerGenTableSort({ name, direction }));
                },
                showResponsive: true,
                tableBodyHeight: "calc(100vh - 340px)",
                toolbar: true,
                print: false,
                viewColumns: false,
                sortThirdClickReset: true,
                onViewColumnsChange: () => {},
              }}
            />
            <Typography
              variant="subtitle2"
              onClick={() => {
                setModalOpen(true);
              }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "primary.main",
                  textDecoration: "underline",
                },
              }}
            >
              Disclaimer and Terms & Conditions
            </Typography>
            {modalOpen && (
              <PowerGenDisclaimerModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
              />
            )}
          </Stack>
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
