import { useState } from "react";
import { Box, Button, Stack, Typography } from "@esgian/esgianui";

import { Loading } from "../../components/Loading";
import { PowerGenDisclaimerModal } from "../../components/PowerGenDisclaimerModal";
import { PowerOutputCompareAdvancedProject } from "../../components/PowerOutputCompareAdvancedProject";
import { PowerOutputCompareProject } from "../../components/PowerOutputCompareProject";
import { PowerOutputHeader } from "../../components/PowerOutputHeader";
import { PowerOutputHistoricalCapacityAreaChart } from "../../components/PowerOutputHistoricalCapacityAreaChart";
import { PowerOutputKeyBoxProject } from "../../components/PowerOutputKeyBoxProject";
import { useSelector } from "../../hooks/use-selector";
import { usePowerGenQueries } from "../../hooks/useQueries/usePowerGenQueries";
import {
  getPowerOutputPeriodType,
  getPowerOutputTimePeriod,
  getPowerOutputWindProject,
} from "../../store/selector/powerOutput";
import { POWER_GEN_FILTERS } from "../../store/slice/powerOutput";
import { GenericType } from "../../types";

export const PowerOutputProfile = () => {
  const selectedProject = useSelector(getPowerOutputWindProject);
  const selectedPeriodType = useSelector(getPowerOutputPeriodType);
  const selectedTimePeriod = useSelector(getPowerOutputTimePeriod);
  const [selectedTab, setSelectedTab] = useState("output");
  const [modalOpen, setModalOpen] = useState(false);
  const { powerDataQuery } = usePowerGenQueries();

  return (
    <Box sx={{ px: 2, py: 2 }}>
      <Box sx={{ overflow: "auto", height: "90vh" }}>
        <Stack direction="column" spacing={2} sx={{ position: "sticky" }}>
          <PowerOutputHeader />
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              disableElevation={true}
              onClick={() => setSelectedTab("output")}
              color={selectedTab === "output" ? "primary" : "secondary"}
              variant="contained"
            >
              Power generation
            </Button>
            <Button
              size="small"
              disableElevation={true}
              onClick={() => setSelectedTab("capacity")}
              color={selectedTab === "capacity" ? "primary" : "secondary"}
              variant="contained"
            >
              Capacity factor
            </Button>
          </Stack>
        </Stack>

        {!!selectedProject && !!selectedPeriodType && selectedTimePeriod ? (
          <Stack direction="column" spacing={2} sx={{ pt: 2 }}>
            <Box>
              {!powerDataQuery.isFetching ? (
                <PowerOutputKeyBoxProject />
              ) : (
                <Loading />
              )}
            </Box>
            <Box>
              {!powerDataQuery.isFetching ? (
                <PowerOutputCompareProject selectedTab={selectedTab} />
              ) : (
                <Loading />
              )}
            </Box>
            {!powerDataQuery.isFetching &&
              selectedPeriodType.id ===
                POWER_GEN_FILTERS.PERIOD_TYPE_OPTIONS[1].id &&
              selectedPeriodType.name ===
                POWER_GEN_FILTERS.PERIOD_TYPE_OPTIONS[1].name && (
                <Box>
                  <PowerOutputCompareAdvancedProject
                    selectedTab={selectedTab}
                  />
                </Box>
              )}
            <Box>
              <PowerOutputHistoricalCapacityAreaChart
                selectedTab={selectedTab}
              />
            </Box>
            <Typography
              variant="subtitle2"
              onClick={() => {
                setModalOpen(true);
              }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "primary.main",
                  textDecoration: "underline",
                },
              }}
            >
              Disclaimer and Terms & Conditions
            </Typography>
            {modalOpen && (
              <PowerGenDisclaimerModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
              />
            )}
          </Stack>
        ) : (
          <Box
            sx={{
              background: ({ palette }: GenericType) =>
                palette.background.paper,
              p: 2,
              height: 200,
              borderRadius: 4,
              my: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: ({ palette }: GenericType) => palette.text.primary,
            }}
          >
            <Typography variant="h6" sx={{ py: 2 }}>
              Please select wind farm and time period
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
