import moment from "moment";

import { GenericType } from "../types";

type Project = {
  windFarmName: string | null;
  country: string;
  turbineModel: string | null;
  equipmentType: string | null;
  vesselName: string | null;
};

type DataObject = {
  date: string;
  numberOfContracts: number;
  averageLeadTime: number | null;
  trailing12Months: number | null;
  averageLeadTimeProjects: Project[];
};

export const hoursToADate = (
  periodName: string,
  unit: "hours" | "days",
  firstOpDate?: string,
) => {
  const translatedPeriod = periodName.split(" ");
  if (translatedPeriod.length < 2) {
    if (periodName === "YTD") {
      const pastDate = moment().startOf("year");
      const todayDate = moment();

      return todayDate.diff(pastDate, unit);
    }
    if (periodName === "Max") {
      const pastDate = moment(firstOpDate);
      const todayDate = moment();
      return todayDate.diff(pastDate, unit);
    }
  }
  const amount = Number(translatedPeriod[0]);
  let translatedUnit: moment.unitOfTime.DurationConstructor | undefined;
  if (translatedPeriod[1] === "year" || translatedPeriod[1] === "years") {
    translatedUnit = "years";
  } else if (
    translatedPeriod[1] === "month" ||
    translatedPeriod[1] === "months"
  ) {
    translatedUnit = "months";
  } else if (
    translatedPeriod[1] === "week" ||
    translatedPeriod[1] === "weeks"
  ) {
    translatedUnit = "weeks";
  } else if (translatedPeriod[1] === "day" || translatedPeriod[1] === "days") {
    translatedUnit = "days";
  } else if (
    translatedPeriod[1] === "hour" ||
    translatedPeriod[1] === "hours"
  ) {
    translatedUnit = "hours";
  } else {
    return 0;
  }
  const pastDate = moment().subtract(amount, translatedUnit);
  const todayDate = moment();
  return todayDate.diff(pastDate, unit);
};

export const pointsBetweenDates = (
  periodName: string,
  originalData: GenericType,
  firstOpDate?: string,
) => {
  const translatedPeriod = periodName.split(" ");
  const filteredOutRecords: GenericType = {};
  if (translatedPeriod.length < 2) {
    if (periodName === "YTD") {
      const pastDate = moment().startOf("year");
      const todayDate = moment();
      Object.keys(originalData).forEach((key: string) => {
        if (
          moment(key).isBetween(pastDate, todayDate) &&
          originalData[key].count > 0
        ) {
          filteredOutRecords[key] = originalData[key];
        }
      });
    }
    if (periodName === "Max") {
      const pastDate = moment(firstOpDate);
      const todayDate = moment();
      Object.keys(originalData).forEach((key: string) => {
        if (
          moment(key).isBetween(pastDate, todayDate) &&
          originalData[key].count > 0
        ) {
          filteredOutRecords[key] = originalData[key];
        }
      });
    }
  } else {
    const amount = Number(translatedPeriod[0]);
    let translatedUnit: moment.unitOfTime.DurationConstructor | undefined;
    if (translatedPeriod[1] === "year" || translatedPeriod[1] === "years") {
      translatedUnit = "years";
    } else if (
      translatedPeriod[1] === "month" ||
      translatedPeriod[1] === "months"
    ) {
      translatedUnit = "months";
    } else if (
      translatedPeriod[1] === "week" ||
      translatedPeriod[1] === "weeks"
    ) {
      translatedUnit = "weeks";
    } else if (
      translatedPeriod[1] === "day" ||
      translatedPeriod[1] === "days"
    ) {
      translatedUnit = "days";
    } else if (
      translatedPeriod[1] === "hour" ||
      translatedPeriod[1] === "hours"
    ) {
      translatedUnit = "hours";
    } else {
      return 0;
    }
    const pastDate = moment().subtract(amount, translatedUnit);
    const todayDate = moment();
    Object.keys(originalData).forEach((key: string) => {
      if (
        moment(key).isBetween(pastDate, todayDate) &&
        originalData[key].count > 0
      ) {
        filteredOutRecords[key] = originalData[key];
      }
    });
  }
  return Object.keys(filteredOutRecords).length;
};

export const chartFillerDates = (data: GenericType[]) => {
  const result: DataObject[] = [];
  if (!data || data.length <= 0) {
    return result;
  }
  for (let i = 0; i < data.length - 1; i++) {
    result.push(data[i] as DataObject); // Add current object to the result

    const currentDate = moment(data[i].date, "MMM YYYY");
    const nextDate = moment(data[i + 1].date, "MMM YYYY");

    // Check if the difference in months is greater than 1 month
    const monthDiff = nextDate.diff(currentDate, "months");

    if (monthDiff > 1) {
      for (let j = 1; j < monthDiff; j++) {
        const fillerDate = currentDate
          .clone()
          .add(j, "months")
          .format("MMM YYYY");
        const fillerObject: DataObject = {
          date: fillerDate,
          numberOfContracts: 0,
          averageLeadTime: 0,
          trailing12Months: 0,
          averageLeadTimeProjects: [
            {
              windFarmName: "-",
              country: "-",
              turbineModel: "-",
              equipmentType: null,
              vesselName: null,
            },
          ],
        };
        result.push(fillerObject); // Add filler object to result
      }
    }
  }
  result.push(data[data.length - 1] as DataObject);
  return result;
};

export const objectFillerDates = (data: GenericType) => {
  const result: GenericType = {};
  const keys = Object.keys(data).sort((a, b) =>
    moment(a, "MMM YYYY").diff(moment(b, "MMM YYYY")),
  );
  if (!keys || keys.length <= 0) {
    return result;
  }
  for (let i = 0; i < keys.length - 1; i++) {
    result[keys[i]] = data[keys[i]]; // Add current object to the result
    const currentDate = moment(keys[i], "MMM YYYY");
    const nextDate = moment(keys[i + 1], "MMM YYYY");

    // Check if the difference in months is greater than 1 month
    const monthDiff = nextDate.diff(currentDate, "months");

    if (monthDiff > 1) {
      for (let j = 1; j < monthDiff; j++) {
        const fillerDate: string = currentDate
          .clone()
          .add(j, "months")
          .format("MMM YYYY");
        result[fillerDate] = []; // Add filler object to result
      }
    }
  }
  result[keys[keys.length - 1]] = data[keys[keys.length - 1]];
  return result;
};
