import { Chart, Plugin } from "chart.js";

type Options = {
  text?: string;
  textColor?: string;
  backgroundColor?: string;
};
type PluginOptions = {
  text?: string;
  textColor: string;
  backgroundColor: string;
};

/**
 * NoDataPlugin is a Chart.js plugin that renders text if there is no data.
 */
export class NoDataPlugin {
  name: string;

  options: Options;

  /**
   * @param options
   */
  constructor(options: Options = {}) {
    this.name = "yAxisTitle";

    this.options = {
      ...options,
    };
  }

  /**
   * Returns the plugin configuration object required by Chart.js.
   * @returns Chart.js plugin configuration object.
   */
  getPlugin(): Plugin {
    return {
      id: "noData",
      beforeDraw: (chart) => this.beforeDraw(chart),
    };
  }

  /**
   * Renders the Y-axis titles on the chart after it is drawn.
   * @param chart - The Chart.js chart instance.
   */
  beforeDraw(chart: Chart) {
    const {
      ctx,
      data,
      chartArea: { top, left, width, height },
    } = chart;
    const { textColor, text = "No Data Available" } = this
      .options as PluginOptions;

    if (data.datasets.length === 0) {
      ctx.save();
      ctx.font = `bold 20px "Noto Sans"`;
      ctx.fillStyle = textColor;
      ctx.textAlign = "center";
      ctx.fillText(text, left + width / 2, top + height / 2);
    }
  }
}
