import { GenericType } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = GenericType[];

type ResponseData = { data: GenericType[] };

export const getPowerOutputTotal: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/PowerGeneration/ByFilters?api-version=2.0`,
  method: "POST",
  data,
});

export const getPowerOutputEnergi: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/PowerGeneration/energistyrelsenpowergeneration`,
  method: "POST",
  data,
});

export const getPowerOutputTai: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/PowerGeneration/taipowergeneration`,
  method: "POST",
  data,
});

export const getPowerOutputEIA: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/PowerGeneration/eiapowergeneration`,
  method: "POST",
  data,
});

export const getPowerOutputEntsoe: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/PowerGeneration/entsoepowergeneration`,
  method: "POST",
  data,
});

export const getPowerOutputEEX: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/PowerGeneration/eexpowergeneration`,
  method: "POST",
  data,
});

export const getPowerOutputElexon: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/PowerGeneration/elexonpowergeneration`,
  method: "POST",
  data,
});
export const getPowerOutputAggregated: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/PowerGeneration/Aggregated`,
  method: "POST",
  data,
});
