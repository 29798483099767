import {
  getPowerOutputTotal,
  getPowerOutputEnergi,
  getPowerOutputTai,
  getPowerOutputEIA,
  getPowerOutputEntsoe,
  getPowerOutputEEX,
  getPowerOutputElexon, getPowerOutputAggregated,
} from "./getPowerOutput";
import { getPowerOutputLookup } from "./getPowerOutputLookup";

export const powerOutput = {
  getPowerOutputLookup,
  getPowerOutputTotal,
  getPowerOutputEnergi,
  getPowerOutputTai,
  getPowerOutputEIA,
  getPowerOutputEntsoe,
  getPowerOutputEEX,
  getPowerOutputElexon,
  getPowerOutputAggregated,
};
