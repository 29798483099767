import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Box, Popover, Stack, Typography } from "@esgian/esgianui";
import { useQuery } from "@tanstack/react-query";
import { Chart } from "chart.js/dist/types";
import moment from "moment";

import { api } from "../../api/api";
import { getErrorMessage } from "../../helper/errorHandling";
import { safelyCallApi } from "../../helper/requestWrapper";
import { useSelector } from "../../hooks/use-selector";
import { useTheme } from "../../hooks/useTheme";
import {
  getPowerOutputCompareWindProjects,
  getPowerOutputWindProject,
} from "../../store/selector/powerOutput";
import { FONT_FAMILY, GenericType, MESSAGE, ThemeModeEnum } from "../../types";
import { ChartData } from "../../types/charts";
type Event = {
  id: number;
  timestamp: string;
  eventTypeId: 2 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 15 | 17;
  eventType: string;
  eventTypeAbbreviation: string;
  eventHeadline: string;
  eventText: string;
  projectId: number;
  percentage: number;
  color: string;
};

// const EVENT_TYPE_ID_TO_SHORT = {
//   2: { name: "N" },
//   4: { name: "SM" },
//   5: { name: "X" },
//   6: { name: "LGHT" },
//   7: { name: "TRAN" },
//   8: { name: "COMM" },
//   9: { name: "BEAR" },
//   10: { name: "OTH" },
//   11: { name: "IA" },
//   12: { name: "OfSS" },
//   13: { name: "OnSS" },
//   15: { name: "UNK" },
//   17: { name: "B" },
// };

const getCircle = (
  event: Event,
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>,
  setHoverEvent: React.Dispatch<React.SetStateAction<null | Event>>,
) => {
  const { percentage, color, timestamp, eventTypeAbbreviation } = event;
  return (
    <div
      onMouseOver={({ currentTarget }) => {
        setAnchorEl(currentTarget);
        setHoverEvent(event);
      }}
      key={`${event.id}-${event.percentage}-event`}
      style={{
        position: "absolute",
        left: `calc(${percentage}% - 15px)`,
        display: "flex",
        height: "50px",
        transition: "all 0.5s",
        top: "-21px",
        flexDirection: "column", // Stack elements vertically
        alignItems: "center", // Center horizontally
        gap: "0px", // Add spacing between elements
        maxWidth: "30px",
        whiteSpace: "nowrap",
      }}
    >
      {/* Text above */}
      <span
        style={{
          fontSize: "10px",
          fontFamily: FONT_FAMILY,
        }}
      >
        {eventTypeAbbreviation}
      </span>

      {/* Circle */}
      <div
        style={{
          height: "0.75em",
          width: "0.75em",
          borderRadius: "50%",
          background: color,
        }}
      ></div>

      {/* Text below */}
      <span
        style={{
          fontSize: "10px",
          fontFamily: FONT_FAMILY,
        }}
      >
        {moment(timestamp).format("MMM DD")}
      </span>
    </div>
  );
};

type ChartView = {
  min: number;
  max: number;
};
type Prop = {
  chartRef: React.RefObject<Chart | undefined>; // Reference to the Chart.js instance
  data: ChartData;
};
export const KeyEventTimeline: FC<Prop> = ({ chartRef, data }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [chartView, setChartView] = useState<ChartView | null>(null);
  const [hoverEvent, setHoverEvent] = React.useState<null | Event>(null);
  const [showMore, setShowMore] = React.useState<boolean>(false);
  const isCtrlPressed = useRef(false); // Track if the Ctrl key is pressed
  const isMouseDown = useRef(false); // Track if the Ctrl key is pressed
  const selectedProject = useSelector(getPowerOutputWindProject);
  const compareWindProjects = useSelector(getPowerOutputCompareWindProjects);
  const { theme } = useTheme();

  useEffect(() => {
    // Create a function to handle keydown events
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        isCtrlPressed.current = true;
      }
    };

    // Handle keyup event to detect when the mouse key is released
    const handleKeyUp = (event: KeyboardEvent) => {
      if (!event.ctrlKey) {
        isCtrlPressed.current = false;
      }
    };
    const handleMouseDown = () => {
      isMouseDown.current = true;
    };

    // Handle keyup event to detect when the mouse key is released
    const handleMouseUp = () => {
      isMouseDown.current = false;
    };
    const handleWheel = (event: WheelEvent | MouseEvent) => {
      if (isCtrlPressed.current && chartRef.current) {
        event.preventDefault(); // Prevent default scrolling behavior
        const view = chartRef.current?.getZoomedScaleBounds()?.x as ChartView;
        if (view) {
          setChartView({ min: Math.floor(view.min), max: Math.ceil(view.max) });
        }
      }
    };

    const handleDrag = (event: MouseEvent) => {
      if (isCtrlPressed.current && isMouseDown.current && chartRef.current) {
        event.preventDefault(); // Prevent default scrolling behavior
        const view = chartRef.current?.getZoomedScaleBounds()?.x as ChartView;
        if (view) {
          setChartView({ min: Math.floor(view.min), max: Math.ceil(view.max) });
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousemove", handleDrag, { passive: false });
    window.addEventListener("wheel", handleWheel, { passive: false });

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("wheel", handleWheel);
    };
  }, [chartRef]);

  const { startDate, endDate } = useMemo(() => {
    if (!data.labels?.length) return { startDate: null, endDate: null };
    const labels = data.labels;
    return {
      startDate: labels[0] as number,
      endDate: labels[labels.length - 1] as number,
    };
  }, [data]);

  const eventColors = useMemo(() => {
    if (!data.datasets.length) return null;
    const res: GenericType = {};
    data.datasets.forEach(({ projectId, borderColor }: GenericType) => {
      res[projectId] = borderColor;
    });
    return res;
  }, [data]);

  const eventsQuery = useQuery({
    queryKey: [
      "events",
      { startDate, endDate, selectedProject, compareWindProjects },
    ],
    enabled: !!startDate && !!endDate && !!selectedProject,
    placeholderData: null,
    queryFn: () => {
      if (!selectedProject || !eventColors) return null;
      const ids: number[] = [];
      if (selectedProject) {
        ids.push(selectedProject.id);
      }
      if (compareWindProjects.length) {
        compareWindProjects.forEach(({ id }) => ids.push(id));
      }
      return safelyCallApi(
        api.events.getProjectEvents({
          ProjectIds: ids,
          StartDate: moment(startDate),
          EndDate: moment(endDate),
        }),
      )
        .then(({ status, data: resp }) => {
          if (status !== 200) return null;

          return resp.map((item: Event) => {
            return { ...item, color: eventColors[item.projectId] };
          }) as Event[];
        })
        .catch((err) => {
          toast.error(`${MESSAGE.Project}: ${getErrorMessage(err)}`);
          return null;
        });
    },
  });

  const dataWithPercentage = useMemo(() => {
    if (!eventsQuery.data?.length) return [];
    if (!data.labels?.length) return [];
    const labels = data.labels;
    let start = labels[0] as number;
    let end = labels[labels?.length - 1] as number;
    if (chartView) {
      start = labels[chartView.min] as number;
      end = labels[chartView.max] as number;
    }

    end = moment(end).startOf("day").valueOf();
    end = moment(end).endOf("day").valueOf();

    return eventsQuery.data
      .filter(({ timestamp }) => {
        const dateValue = moment(timestamp).valueOf();
        return dateValue >= start && dateValue <= end;
      })
      ?.map((item) => {
        const totalRange = end - start;
        const dateValue = moment(item.timestamp)
          .startOf("day")
          .add(12, "hours")
          .valueOf();
        const percentage = ((dateValue - start) / totalRange) * 100;
        return {
          ...item,
          percentage: percentage, // Add percentage to each item, formatted to 2 decimals
        } as Event;
      });
  }, [eventsQuery.data, data.labels, chartView]);

  const handleClose = () => {
    setAnchorEl(null);
    setHoverEvent(null);
    setShowMore(false);
  };

  return (
    <Box
      sx={{
        width: "calc(100% - 2.5em)",
        height: 2,
        marginTop: "24px !important",
        position: "relative",
        marginLeft: "2.4em !important",
        background: theme.mode === ThemeModeEnum.Dark ? "white" : "black",
      }}
    >
      {dataWithPercentage.map((event) =>
        getCircle(event, setAnchorEl, setHoverEvent),
      )}
      <Popover
        anchorEl={Boolean(anchorEl) && !!hoverEvent ? anchorEl : null}
        open={Boolean(anchorEl) && !!hoverEvent}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              maxWidth: "15em",
              padding: 1,
              opacity: anchorEl ? 1 : 0,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              border: ({ palette }: GenericType) =>
                `1px solid ${palette.border.main}`,
              bgcolor: "background.default",
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: "calc(50% - 5px)",
                width: 10,
                height: 10,
                bgcolor: "background.default",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
                borderTop: ({ palette }: GenericType) =>
                  `1px solid ${palette.border.main}`,
                borderLeft: ({ palette }: GenericType) =>
                  `1px solid ${palette.border.main}`,
              },
            },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {!!anchorEl && !!hoverEvent && (
          <Stack>
            <Typography
              sx={{ alignSelf: "center", textDecoration: "underline" }}
              variant="caption"
            >
              {hoverEvent?.eventHeadline}
            </Typography>
            {!showMore ? (
              <Typography
                onClick={() => {
                  setShowMore(true);
                }}
                variant="caption"
                sx={{
                  color: ({ palette }: GenericType) => palette.primary.main,
                  cursor: "pointer",
                }}
              >
                Show more...
              </Typography>
            ) : (
              <Typography sx={{ pt: 4 }} variant="caption">
                {hoverEvent?.eventText}
              </Typography>
            )}
          </Stack>
        )}
      </Popover>
    </Box>
  );
};
