import { FC } from "react";
import { Table } from "@esgian/esgianui";

import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getRowsPerPage, getThemeMode } from "../../store/selector/common";
import { getSortingInfo } from "../../store/selector/contract";
import {
  setRowsPerPage,
  setSelectedFloatingTechnologyId,
  setSelectedProjectId,
  setSelectedTurbineModelId,
} from "../../store/slice/common";
import { setSortingInfo } from "../../store/slice/contract";
import { GenericType, UnifiedEquipmentContract } from "../../types";

type Prop = {
  columns: GenericType[];
  data: UnifiedEquipmentContract[];
};

export const EquipmentContractList: FC<Prop> = ({ columns, data }) => {
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const rowsPerPage = useSelector(getRowsPerPage);
  const sortingInfo = useSelector(getSortingInfo);

  return (
    <Table
      app="wind"
      columns={columns}
      data={data}
      elevation={0}
      mode={themeMode}
      options={{
        download: true,
        search: false,
        downloadOptions: {
          filename: `equipment_contract_data.csv`,
        },
        onDownload: (
          buildHead: (columns: object[]) => string[],
          buildBody: (data: object) => string,
          propColumns: GenericType[],
          propData: GenericType[],
        ) => {
          const tableData = [...propData].map((tableRow) => {
            const res: string[] = [];
            Object.keys(tableRow.data).forEach((key) => {
              const val = tableRow.data[key];
              if (Array.isArray(val)) {
                res[Number(key)] = val
                  .map(({ props }) => props?.children ?? " ")
                  .join(", ");
              } else {
                res[Number(key)] = val;
              }
            });
            return { data: res, index: undefined };
          });
          return "\uFEFF" + buildHead(propColumns) + buildBody(tableData);
        },
        expandableRows: false,
        filter: false,
        filterType: "multiselect",
        hideToolbarMargin: false,
        pagination: true,
        responsive: "vertical",
        rowsPerPage,
        onChangeRowsPerPage: (numberOfRows: number) => {
          dispatch(setRowsPerPage(numberOfRows));
        },
        onCellClick: (_: GenericType, { colIndex, dataIndex }: GenericType) => {
          const column = columns[colIndex].name;
          const item: GenericType = data[dataIndex];
          if (column === "windFarm" && !item.windFarmId.includes(",")) {
            dispatch(setSelectedProjectId(item.windFarmId));
          }
          if (column === "turbineModel") {
            dispatch(setSelectedTurbineModelId(item.turbineModelId));
          }
          if (column === "technologyType") {
            dispatch(
              setSelectedFloatingTechnologyId(item.floatingTechnologyId),
            );
          }
        },
        rowsPerPageOptions: [25, 50, 100],
        selectableRows: "none",
        sortOrder: sortingInfo,
        onColumnSortChange: (name: string, direction: string) => {
          dispatch(setSortingInfo({ name, direction }));
        },
        showResponsive: true,
        tableBodyHeight: "calc(100vh - 320px)",
        toolbar: true,
        print: false,
        viewColumns: true,
        onViewColumnsChange: () => {},
      }}
    />
  );
};
