import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SortingInfo } from "../../types";

export const POWER_GEN_FILTERS = {
  PERIOD_OPTIONS: [
    {
      responseKey: "oneWeeks",
      chartFormat: "MMM DD HH:mm",
      id: 1,
      name: "1 week",
      number: 1,
      unit: "weeks",
    },
    {
      responseKey: "oneMonths",
      chartFormat: "MMM DD HH:mm",
      id: 2,
      name: "1 month",
      number: 1,
      unit: "months",
    },
    {
      responseKey: "threeMonths",
      chartFormat: "MMM DD HH:mm",
      id: 3,
      name: "3 months",
      number: 3,
      unit: "months",
    },
    {
      responseKey: "sixMonths",
      chartFormat: "MMM DD YYYY",
      id: 4,
      name: "6 months",
      number: 6,
      unit: "months",
    },
    {
      responseKey: "ytd",
      chartFormat: "MMM DD YYYY",
      id: 5,
      name: "YTD",
      number: 1,
      unit: "years",
    },
    {
      responseKey: "oneYears",
      chartFormat: "MMM DD YYYY",
      id: 6,
      name: "1 year",
      number: 1,
      unit: "years",
    },
    {
      responseKey: "threeYears",
      chartFormat: "MMM DD YYYY",
      id: 7,
      name: "3 years",
      number: 3,
      unit: "years",
    },
    {
      responseKey: "fiveYears",
      chartFormat: "MMM DD YYYY",
      id: 8,
      name: "5 years",
      number: 5,
      unit: "years",
    },
    {
      responseKey: "max",
      chartFormat: "MMM DD YYYY",
      id: 10,
      name: "Max",
      number: 100,
      unit: "years",
    },
  ],
  CALENDAR_OPTIONS: [
    {
      responseKey: "monthly",
      chartFormat: "MMM YYYY",
      unit: "months",
      name: "Monthly",
      id: 1,
    },
    {
      responseKey: "quarterly",
      chartFormat: "[Q]Q YYYY",
      unit: "quarters",
      name: "Quarterly",
      id: 2,
    },
    {
      responseKey: "yearly",
      chartFormat: "YYYY",
      unit: "years",
      name: "Yearly",
      id: 3,
    },
  ],
  PERIOD_TYPE_OPTIONS: [
    { id: 1, name: "Trailing time period" },
    {
      id: 2,
      name: "Calendar year",
    },
  ],
};

type PeriodType = {
  id: number;
  name: string;
};
export type TimePeriodType = {
  number?: number;
  chartFormat?: string;
  responseKey?: string;
  unit?:
    | "years"
    | "months"
    | "weeks"
    | "days"
    | "hours"
    | "minutes"
    | "seconds"
    | "milliseconds";
  id: number;
  name: string;
};
type Project = {
  id: number;
  name: string;
  sourceName?:
    | "Elexon"
    | "Taipower"
    | "EEX"
    | "ENTSOE"
    | "Energistyrelsen"
    | "EIA";
};

type State = {
  tableSortOrder: SortingInfo;
  selectedWindProject: Project | null;
  compareWindProjects: Project[] | [];
  periodType: PeriodType | null;
  timePeriod: TimePeriodType | null;
  disabledOption: PeriodType | null;
};

const initialState: State = {
  tableSortOrder: {
    direction: "asc",
    name: "name",
  },
  periodType: POWER_GEN_FILTERS.PERIOD_TYPE_OPTIONS[0],
  disabledOption: null,
  selectedWindProject: null,
  compareWindProjects: [],
  timePeriod: null,
};

const powerOutputSlice = createSlice({
  name: "powerOutput",
  initialState,
  reducers: {
    setPowerGenTableSort: (state, action: PayloadAction<SortingInfo>) => {
      state.tableSortOrder = action.payload;
    },
    setCompareWindProjects: (state, action: PayloadAction<Project[]>) => {
      state.compareWindProjects = action.payload;
    },

    deleteCompareWindProjects: (state, action: PayloadAction<string>) => {
      state.compareWindProjects = state.compareWindProjects.filter(
        ({ id }) => `${id}` !== `${action.payload}`,
      );
    },
    setPowerGenWindProject: (state, action: PayloadAction<Project>) => {
      if (
        action.payload.sourceName &&
        ["Energistyrelsen", "EIA"].includes(action.payload.sourceName)
      ) {
        state.timePeriod = POWER_GEN_FILTERS
          .CALENDAR_OPTIONS[0] as TimePeriodType;
        state.periodType = POWER_GEN_FILTERS.PERIOD_TYPE_OPTIONS[1];
        state.disabledOption = POWER_GEN_FILTERS.PERIOD_TYPE_OPTIONS[0];
      } else {
        state.periodType = POWER_GEN_FILTERS.PERIOD_TYPE_OPTIONS[0];
        state.timePeriod = POWER_GEN_FILTERS
          .PERIOD_OPTIONS[5] as TimePeriodType;
        state.disabledOption = null;
      }
      state.selectedWindProject = action.payload;
    },
    setPowerGenPeriodType: (state, action: PayloadAction<PeriodType>) => {
      if (action.payload.id === 1) {
        state.timePeriod = POWER_GEN_FILTERS
          .PERIOD_OPTIONS[0] as TimePeriodType;
      } else {
        state.timePeriod = POWER_GEN_FILTERS
          .CALENDAR_OPTIONS[0] as TimePeriodType;
      }

      state.periodType = action.payload;
    },
    setPowerGenTimePeriod: (state, action: PayloadAction<TimePeriodType>) => {
      state.timePeriod = action.payload;
    },
  },
});

export const {
  setPowerGenTableSort,
  setPowerGenWindProject,
  setPowerGenPeriodType,
  setCompareWindProjects,
  deleteCompareWindProjects,
  setPowerGenTimePeriod,
} = powerOutputSlice.actions;
export const powerOutput = powerOutputSlice.reducer;
