import { FC, ReactNode, useEffect, useState } from "react";
import { matchPath, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  DarkModeSwitch,
  Dashboard,
  Modal,
  Typography,
} from "@esgian/esgianui";
import axios from "axios";

import { getAuthToken } from "../../helper/cookies";
import { useAuth } from "../../hooks/use-auth";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  initializeAppInsights,
  updateTelemetryInitializer,
} from "../../hooks/useAppInsights";
import { getSideMenu, getThemeMode } from "../../store/selector/common";
import { setSideMenu, setThemeMode } from "../../store/slice/common";
import {
  AuthorizeUser,
  GenericType,
  ROUTES_CONFIG,
  ThemeModeEnum,
} from "../../types";
import { Header } from "../Header";
import { Loading } from "../Loading";
import { getSideMenuList } from "../SideMenu";

const useStyles = () => {
  return {
    container: {
      "& .MuiBox-root": {
        "& > .MuiGrid-root": {
          m: 0,
          p: 0,
          maxWidth: "100%",
        },
      },
    },
    content: {
      width: "100%",
      height: "calc(100vh - 72px)",
    },
  };
};

type Prop = {
  children: ReactNode;
};

export const Layout: FC<Prop> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authorizeUser, isAuthenticated, user } = useAuth();
  const classes = useStyles();

  const themeMode = useSelector(getThemeMode);
  const isSideMenuOpen = useSelector(getSideMenu);

  const [subSectionsOpen, setSubSectionsOpen] = useState<{
    [key: string]: boolean;
  }>({});
  const [isThemeMode, setIsThemeMode] = useState<string | null>(
    localStorage.getItem("themeMode"),
  );

  const routeName = Object.keys(ROUTES_CONFIG).find((item: string) =>
    matchPath(ROUTES_CONFIG?.[item]?.url, location.pathname),
  );
  const routeConfig = ROUTES_CONFIG[routeName || ""];

  const routeChange = (path: string) => {
    navigate(path);
  };

  const subItemCloseOrOpen = (item: GenericType) => {
    setSubSectionsOpen({ ...subSectionsOpen, ...item });
  };

  const handleThemeChange = () => {
    let mode = ThemeModeEnum.Dark;
    if (themeMode === ThemeModeEnum.Dark) {
      mode = ThemeModeEnum.Light;
    }
    dispatch(setThemeMode(mode));
  };

  const handleLogout = async () => {
    const userToken = getAuthToken();
    try {
      await axios.delete(
        `${process.env.REACT_APP_AUTH_URL}/logout?token=${userToken}`,
      );
      window.location.href = `${process.env.REACT_APP_LOGIN_URL}/?version=2&pathName=${window.location.pathname}`;
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleFaqClick = async () => {
    window.location.href = ROUTES_CONFIG.faq.url;
  };

  const handleLoginClick = async () => {
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}/?version=2&pathName=${window.location.pathname}`;
  };

  useEffect(() => {
    if (isAuthenticated === AuthorizeUser.Default) {
      authorizeUser();
    }

    if (isAuthenticated === AuthorizeUser.Authorized) {
      initializeAppInsights();
      updateTelemetryInitializer(user);
    }
    if (isAuthenticated === AuthorizeUser.NonAuthorized) {
      if (!routeConfig.public) {
        navigate(ROUTES_CONFIG.Enquiry.url);
      }
    }
    if (
      isAuthenticated === AuthorizeUser.Authorized &&
      !user?.profile.permissionList.includes("WIND_powerGen") &&
      (routeConfig.url === "/power-generation" ||
        routeConfig.url === "/power-generation-details")
    ) {
      navigate(ROUTES_CONFIG.Enquiry.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, routeConfig]);

  return (
    <Box sx={classes.container}>
      <Dashboard
        isAuthenticated={isAuthenticated === AuthorizeUser.Authorized}
        handleLoginClick={handleLoginClick}
        handleFaqClick={handleFaqClick}
        handleSignInClick={() => {}}
        displayNewsFeed={false}
        handleLogoutClick={handleLogout}
        user={{}}
        handleThemeChange={handleThemeChange}
        themeMode={themeMode}
        sideMenuOpen={isSideMenuOpen}
        setSideMenuOpen={(isOpen: boolean) => {
          dispatch(setSideMenu(isOpen));
        }}
        app="wind"
        appTitle="Offshore Wind"
        newsFeedOpen={() => {}}
        setNewsFeedOpen={() => {}}
        sideMenuContent={getSideMenuList(
          routeChange,
          subSectionsOpen,
          subItemCloseOrOpen,
          isAuthenticated,
          user,
        )}
      >
        {routeConfig.public || isAuthenticated === AuthorizeUser.Authorized ? (
          <>
            <Header />
            <Box sx={classes.content}>
              <Box sx={{ background: "white" }}>
                <Modal
                  open={!isThemeMode}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Welcome to the enhanced interface to Wind Analytics 🙂 You
                      have the option to view in dark or light mode
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <DarkModeSwitch
                      checked={themeMode === ThemeModeEnum.Light}
                      handleChange={() => {
                        if (themeMode === ThemeModeEnum.Dark) {
                          dispatch(setThemeMode(ThemeModeEnum.Light));
                        } else {
                          dispatch(setThemeMode(ThemeModeEnum.Dark));
                        }
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      onClick={() => {
                        dispatch(setThemeMode(themeMode));
                        setIsThemeMode(themeMode);
                      }}
                    >
                      Ok
                    </Button>
                  </Box>
                </Modal>
              </Box>
              {children}
            </Box>
          </>
        ) : isAuthenticated === AuthorizeUser.IsLoading ? (
          <Box sx={{ width: "100%" }}>
            <Loading />
          </Box>
        ) : null}
      </Dashboard>
    </Box>
  );
};
