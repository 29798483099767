export enum ThemeModeEnum {
  Dark = "dark",
  Light = "light",
}

export enum ViewTabEnum {
  Chart = "Chart",
  Map = "Map",
  List = "List",
}

export enum FilterItemType {
  Autocomplete = "Autocomplete",
  MultiSelect = "Multi-Select",
  Slider = "Slider",
  Date = "Date",
}

export enum ProjectStatusEnum {
  Confirmed = "Confirmed",
  Proposed = "Proposed",
  Installing = "Installing",
  Operational = "Operational",
}

export enum FeatureLegendsTypeEnum {
  LeaseArea = "LeaseArea",
  ProjectProposed = "Proposed",
  ProjectConfirmed = "Confirmed",
  ProjectInstalling = "Installing",
  ProjectSuspended = "Suspended",
  ProjectOperational = "Operational",
  ProjectDecommissioned = "Decommissioned",
  ProjectUndefined = "Undefined",
}

export enum FeatureTypeEnum {
  LeaseArea = 3,
  Project = 4,
  ProjectProposed = "Proposed",
  ProjectSuspended = "Suspended",
  ProjectConfirmed = "Confirmed",
  ProjectInstalling = "Installing",
  ProjectOperational = "Operational",
  ProjectDecommissioned = "Decommissioned",
  ProjectUndefined = "Undefined",
}

export enum MapSource {
  FactorySource = "factory-source",
  CircleSource = "circle-source",
  CountrySource = "country-source",
  Polygon = "polygon-source",
  Point = "point-source",
  PointLabel = "point-label-source",
  PolygonLabel = "polygon-label-source",
  Undefined = "undefined-source",
  SpiderCenterCircle = "spider-center-circle",
  SpiderCircle = "spider-circle",
  SpiderLine = "spider-line",
}

export enum MapLayer {
  Factory = "factory-layer",
  CircleCount = "circle-count-layer",
  Circle = "circle-layer",
  Country = "country-layer",
  CountryBoundaries = "country_boundaries",
  Polygon = "polygon-layer",
  Point = "point-layer",
  PointLabel = "point-label-layer",
  PolygonLabel = "polygon-label-layer",
  UndefinedIcon = "undefined-icon-layer",
  UndefinedImage = "undefined-icon",
  SpiderCenterCircle = "spider-center-circle",
  SpiderCircle = "spider-circle",
  SpiderLine = "spider-line",
  SpiderCircleLabel = "spider-circle-label",
}

export enum WindLeasingAndProjectTabEnum {
  Country = "country",
  LeaseRound = "lease-round",
  LeaseArea = "lease-area",
  Project = "project",
  WindFarmDeveloper = "wind-farm-developer",
}

export enum FactoriesTabEnum {
  TurbineContracts = "turbine-contracts",
  FloatingContracts = "floating-contracts",
  FoundationContracts = "foundation-contracts",
  CableContracts = "cable-contracts",
  SubstationContracts = "substation-contracts",
  TowerContracts = "tower-contracts",
}

export enum FloatingTechnologyChartTabEnum {
  MarketAdaption = "market-adaption",
  MarketShare = "market-share",
}

export enum TimelineLeaseRoundKey {
  AcceptingProposals = "Accepting proposals",
  Closed = "Closed",
  Completed = "Completed",
}

export enum ContractTabEnum {
  Equipment = "Equipment",
  Vessel = "Vessel",
}

export enum EquipmentContractTabEnum {
  Turbines = "turbines",
  Foundations = "foundations",
  Cables = "cables",
  Substations = "substations",
  Towers = "towers",
  FloatingEquipment = "floating-equipment",
}

export enum VesselContractTabEnum {
  AllVessels = "all-vessels",
  Installation = "installation",
  CableLay = "cable-lay",
  FloatingInstallation = "floating-installation",
  Maintenance = "maintenance",
}

export enum SupplyDemandSeriesEnum {
  "series_0" = "contractedDemand",
  "series_1" = "confirmed",
  "series_2" = "proposed",
  "series_3" = "supply",
}

export enum AnalyticsTabEnum {
  PerYear = "per-year",
  Cumulative = "cumulative",
}

export enum AnalyticsSubTabEnum {
  Capacity = "capacity",
  NumberTurbines = "numberTurbine",
}

export enum VesselContractChartTabEnum {
  ContractOverview = "contract-overview",
  VesselOverview = "vessel-overview",
  LeadTime = "lead-time",
}

export enum VesselStatusEnum {
  Foundation = "Foundation",
  Oms = "OMS",
  Turbine = "Turbine",
  VesselUnderConstruction = "Vessel under construction",
  CableLay = "Cable lay",
  FoundationEstimated = "Foundation estimated",
  OMSEstimated = "OMS estimated",
  TurbineEstimated = "Turbine estimated",
  VesselUnderConstructionEstimated = "Vessel under construction estimated",
  CablelayEstimated = "Cable lay estimated",
}

export enum ActivityTypeEnum {
  Foundation = "Foundation",
  Maintenance = "Maintenance",
  Turbine = "Turbine",
  Other = "Other",
  StandBy = "Stand by",
  InPort = "In port",
  Transit = "Transit",
}

export enum PowerOutputEnum {
  WindFarm = "wind-farm",
  Developer = "developer",
  TurbineSupplier = "turbine",
  Country = "country",
  ControlArea = "control-area",
}

export enum PowerOutputPeriodEnum {
  "1 day" = 1,
  "1 week" = 2,
  "1 month" = 3,
  "3 months" = 4,
  "6 months" = 5,
  "YTD" = 6,
  "1 year" = 7,
  "3 years" = 8,
  "5 years" = 9,
  "Max" = 11,
}

export enum PowerOutputSelectionPCEnum {
  "Trailing time period" = 1,
  "Calendar year" = 2,
}

export enum PowerOutputCalendarEnum {
  "Monthly" = 1,
  "Quarterly" = 2,
  "Yearly" = 3,
}

export enum PowerOutputPeriodTypeEnum {
  "Hourly" = 1,
  "Daily" = 2,
  "Weekly" = 3,
  "Monthly" = 4,
  "Yearly" = 5,
}

export enum AuthorizeUser {
  Default = 1,
  IsLoading = 2,
  Authorized = 3,
  NonAuthorized = 4,
}
