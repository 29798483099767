/* eslint-disable indent */
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Button, Divider, getEsgianTheme, Modal } from "@esgian/esgianui";
import moment, { Moment } from "moment";

import { api } from "../../../api/api";
import { getErrorMessage } from "../../../helper/errorHandling";
import { extractContracts } from "../../../helper/map";
import { useSelector } from "../../../hooks/use-selector";
import { getCountries, getThemeMode } from "../../../store/selector/common";
import {
  Country,
  GenericType,
  IdNamePair,
  MESSAGE,
  ROUTES_CONFIG,
  THEME,
  ThemeModeEnum,
} from "../../../types";
import { Loading } from "../../Loading";
import Paragraph from "../Paragraph";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    modalContainer: {
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: "22.5rem",
      },
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
    },
    divider: {
      my: 3,
    },
    contentBox: {
      py: 3,
      px: 2,
    },
    loadingContainer: {
      height: "calc(100vh - 66px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
};

const cableIds = [3, 31, 32, 42];
const installationIds = [5, 9, 11, 13, 14, 15, 16, 19, 20, 21, 24, 17, 18];
const floatingIds = [1, 33, 26, 39, 4, 29, 8, 25, 28, 27];
const foundationIds = [5, 13, 14, 15, 16, 17, 18, 19, 20, 21];

export const ProjectDrawer: FC<{
  id?: number | null;
  onClose: () => void;
  onLeaseRoundClick: (id: number) => void;
}> = ({ id, onClose, onLeaseRoundClick }) => {
  const countries = useSelector(getCountries);
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);

  const [data, setData] = useState<GenericType | null>(null);

  const fetchProjectData = async (projectId: number) => {
    try {
      const { data: resp } = await api.project.getProjectListInfo({
        windProjectIds: [projectId],
      });

      const {
        windProject,
        developers,
        leaseRegion,
        projectRegion,
        seaRegion,
        contracts,
      } = resp[0];
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { equipmentContracts, vesselContracts, windProjectContracts } =
        contracts;
      let mergedContracts: GenericType[] = [];

      // Iterate through equipmentContracts
      for (const key in equipmentContracts) {
        if (equipmentContracts[key] && Array.isArray(equipmentContracts[key])) {
          mergedContracts.push(...equipmentContracts[key]);
        }
      }
      mergedContracts = mergedContracts?.map((c: GenericType) => c?.contract);

      // Iterate through vesselContracts if it is not null
      if (vesselContracts && vesselContracts.length > 0) {
        for (const key in vesselContracts) {
          if (vesselContracts[key] && Array.isArray(vesselContracts[key])) {
            mergedContracts.push(...vesselContracts[key]);
          }
        }
      }
      const vesselContractsList = vesselContracts?.length
        ? extractContracts(vesselContracts)
        : [];
      const foundationMerged = [
        ...(vesselContracts?.flatMap((c: GenericType) => c.turbineContracts) ||
          []),
        ...(windProjectContracts?.length
          ? windProjectContracts.filter((wc: GenericType) => wc.startDate)
          : []),
      ]?.filter(
        (c: GenericType) =>
          (foundationIds.includes(c?.equipmentTypes?.[0]?.id) &&
            c?.workType?.type === "Installation") ||
          (foundationIds.includes(c?.equipmentTypeIds?.[0]) &&
            c?.contractWorkType?.type === "Installation"),
      );

      const foundationInstallationStartDate =
        foundationMerged
          ?.map((contract: GenericType) => moment(contract.startDate))
          ?.reduce((earliest: Moment | undefined, current: Moment) => {
            if (!earliest || current.isBefore(earliest)) {
              return current;
            }
            return earliest;
          }, undefined)
          ?.format("YYYY-MM-DD") || null;

      const turbineMerged = [
        ...(vesselContracts
          ?.flatMap((c: GenericType) => c.turbineContracts)
          ?.filter(
            (c: GenericType) => c?.equipmentTypes?.[0]?.type === "Turbine",
          ) || []),
        ...(windProjectContracts?.length
          ? windProjectContracts.filter(
              (wc: GenericType) =>
                wc.startDate && wc.equipmentTypeIds.includes(11),
            )
          : []),
      ]?.filter(
        (c: GenericType) =>
          c?.workType?.type === "Installation" ||
          c?.contractWorkType?.type === "Installation",
      );
      const turbineInstallationStartDate =
        turbineMerged
          ?.map((contract: GenericType) => moment(contract.startDate))
          ?.reduce((earliest: Moment | undefined, current: Moment) => {
            if (!earliest || current.isBefore(earliest)) {
              return current;
            }
            return earliest;
          }, undefined)
          ?.format("YYYY-MM-DD") || null;

      const turbineSupplier = equipmentContracts?.turbineContracts
        ?.filter(
          ({
            contract: {
              contract: { contract },
            },
          }: GenericType) =>
            contract?.contractWorkType?.type === "Manufacturing",
        )
        .map(
          ({
            contract: {
              contract: { contract },
            },
          }: GenericType) =>
            contract?.contractedCompanies?.map(
              (c: GenericType) => c?.contractee?.name,
            ),
        )
        ?.flat()
        .join(", ");

      const foundationSupplier = equipmentContracts?.foundationContracts
        ?.filter(
          ({
            contract: {
              contract: { contract },
            },
          }: GenericType) =>
            contract?.contractWorkType?.type === "Manufacturing",
        )
        .map(
          ({
            contract: {
              contract: { contract },
            },
          }: GenericType) =>
            contract?.contractedCompanies?.map(
              (c: GenericType) => c?.contractee?.name,
            ),
        )
        ?.flat()
        .join(", ");

      const foundationContracts = equipmentContracts?.foundationContracts ?? [];
      const turbineContracts = equipmentContracts?.turbineContracts ?? [];
      const port = [...foundationContracts, ...turbineContracts]
        ?.filter(
          ({
            contract: {
              contract: { contract },
            },
          }: GenericType) =>
            contract?.contractWorkType?.type === "Installation",
        )
        .flatMap(
          ({
            contract: {
              contract: { contract },
            },
          }: GenericType) =>
            contract?.ports?.map((p: GenericType) => p.portName) || [],
        )
        ?.filter((c: string) => c)
        .reduce((acc, portName) => {
          if (!acc.includes(portName)) {
            acc.push(portName);
          }
          return acc;
        }, [] as string[])
        .join(", ");

      const turbineInstaller = vesselContracts
        ?.map((c: GenericType) =>
          c.turbineContracts.map((tc: GenericType) => ({
            ...tc,
            vesselName: c.vessel.vesselName,
          })),
        )
        .flat()
        ?.filter(
          (c: GenericType) =>
            c?.equipmentTypes?.[0]?.id === 11 &&
            (c.workType?.type === "Installation" ||
              (c.contractStatus?.status === "Speculative" && c?.vesselName)),
        )
        .map((c: GenericType) =>
          c.contractedParties.map((p: GenericType) => p?.contractee?.name),
        )
        .flat()
        .join(", ");

      const cableInstaller = vesselContracts
        ?.map((c: GenericType) =>
          c.turbineContracts.map((tc: GenericType) => ({
            ...tc,
            vesselName: c.vessel.vesselName,
          })),
        )
        .flat()
        ?.filter(
          (c: GenericType) =>
            cableIds.includes(c?.equipmentTypes?.[0]?.id) &&
            (c.workType?.type === "Installation" ||
              (c.contractStatus?.status === "Speculative" && c?.vesselName)),
        )
        .map((c: GenericType) =>
          c.contractedParties.map((p: GenericType) => p?.contractee?.name),
        )
        .flat()
        .join(", ");

      const foundationInstaller = vesselContracts
        ?.map((c: GenericType) =>
          c.turbineContracts.map((tc: GenericType) => ({
            ...tc,
            vesselName: c.vessel.vesselName,
          })),
        )
        .flat()
        ?.filter(
          (c: GenericType) =>
            foundationIds.includes(c?.equipmentTypes?.[0]?.id) &&
            (c?.workType?.type === "Installation" ||
              (c.contractStatus?.status === "Speculative" && c?.vesselName)),
        )
        .map((c: GenericType) =>
          c.contractedParties.map((p: GenericType) => p?.contractee?.name),
        )
        .join(", ");
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const result = {
        keyInfo: {
          project: windProject?.name,
          country:
            windProject?.countryId &&
            countries?.find(
              (item: Country) => item.id === windProject.countryId,
            )?.name,
          developers: developers
            ?.map(
              (dev: GenericType) =>
                `${dev.companyShortName} ${dev?.ownershipPercentage > 0 ? dev?.ownershipPercentage + "%" : ""}`,
            )
            .join(", "),
          projectStatus: windProject?.projectStatus?.status,
          projectType: windProject?.projectType?.type,
          capacity: windProject?.capacityMw,
          numberOfTurbines: windProject?.numTurbines,
          operationDate: windProject?.operationDate,
          decommissioningDate: windProject?.decommissioningDate,
          foundationInstallationDate: foundationInstallationStartDate ?? null,
          turbineInstallationDate: turbineInstallationStartDate ?? null,
        },
        projectDetails: {
          leaseRound: windProject?.leaseRound?.name,
          leaseAreas: leaseRegion?.map((l: GenericType) => l.name).join(", "),
          seaRegion: seaRegion?.map((s: GenericType) => s.name).join(", "),
          fid: windProject?.fid
            ? moment(windProject?.fid).format("YYYY-MM-DD")
            : "",
          maxWaterDepth: projectRegion?.[0]?.maxDepthM,
          intertidal: windProject?.fixedProjectDetails?.every(
            (detail: GenericType) => detail.intertidal,
          )
            ? "Yes"
            : "No",
          freshWaterLocation: windProject?.freshWaterLocation ? "Yes" : "No",
          distanceShore:
            projectRegion?.[0]?.maxDistFromShoreNm ||
            projectRegion?.[0]?.minDistFromShoreNm,
          area: projectRegion?.[0]?.areaSqkm?.toFixed(0),
        },
        equipment: {
          turbineSupplier,
          turbineModel: windProject?.turbineModels
            ?.map((t: IdNamePair) => t.name)
            ?.join(", "),
          turbineNominalPower: windProject?.turbineModels
            ?.map((t: GenericType) => t.ratedPowerMw)
            ?.join(". "),
          tipHeight: windProject?.tipHeight,
          hubHeight: windProject?.hubHeightM,
          rotorDiameter: windProject?.turbineModels
            ?.map((t: GenericType) => t.rotorDiameterM)
            ?.join(", "),
          foundationSupplier: foundationSupplier,
          foundationType: windProject?.fixedProjectDetails
            ?.map((detail: GenericType) => detail?.fixedFoundationType?.type)
            ?.filter((type: string) => type)
            .join(", "),
        },
        vessels: {
          mainPortName: port,
          turbineInstaller,
          cableInstaller,
          foundationInstaller,
        },
        floating:
          windProject?.projectType?.type === "Floating foundation"
            ? {
                floatingMaterial:
                  windProject?.floatingProjectDetails?.[0]
                    ?.floatingFoundationTechnology?.floaterMaterial?.material,
                floatingTechnology:
                  windProject?.floatingProjectDetails?.[0]
                    ?.floatingFoundationTechnology?.name,
                floaterType:
                  windProject?.floatingProjectDetails?.[0]
                    ?.floatingFoundationTechnology?.floaterType?.type,
                anchorMaterial:
                  windProject?.floatingProjectDetails?.[0]?.anchorMaterial
                    ?.material,
                totalNumberAnchors:
                  windProject?.floatingProjectDetails?.[0]?.numAnchors,
                mooringLineType:
                  windProject?.floatingProjectDetails?.[0]?.mooringType?.type,
                totalNumberMooringLines:
                  windProject?.floatingProjectDetails?.[0]?.numMooringLines,
                mooringLineLength:
                  windProject?.floatingProjectDetails?.[0]?.mooringLineLengthM,
                displacementPerUnit:
                  windProject?.floatingProjectDetails?.[0]?.displacementCbm,
                turbinesPerFloatingUnit:
                  windProject?.floatingProjectDetails?.[0]
                    ?.floatingFoundationTechnology?.turbinesPerFoundation,
                draught: windProject?.floatingProjectDetails?.[0]?.draughtM,
                hullMassPerUnit:
                  windProject?.floatingProjectDetails?.[0]?.hullMassMt,
                ballastMassPerUnit:
                  windProject?.floatingProjectDetails?.[0]?.ballastMassMt,
              }
            : null,
        hydrogen: windProject?.hydrogenProjectDetail
          ? {
              hydrogenProject: windProject?.hydrogenProjectDetail?.name,
              hydrogenDeveloper:
                windProject?.hydrogenProjectDetail?.developer?.companyName,
              hydrogenTechnology:
                windProject?.hydrogenProjectDetail?.hydrogenTechnology?.name,

              dateOnline: windProject?.hydrogenProjectDetail?.operationDate,
              electrolysisMethod:
                windProject?.hydrogenProjectDetail?.hydrogenTechnology
                  ?.electrolysisTechnology?.technology,
              electrolyserSupplier:
                windProject?.hydrogenProjectDetail?.supplier?.companyName,

              storageMethod:
                windProject?.hydrogenProjectDetail?.hydrogenTechnology
                  ?.hydrogenStorageMethod?.method,

              distributionMethod:
                windProject?.hydrogenProjectDetail?.hydrogenTechnology
                  ?.hydrogenDistributionMethod?.method,
            }
          : undefined,
        oilAndGas: windProject?.oilGasProjectDetail
          ? {
              oilAndGasFields:
                windProject?.oilGasProjectDetail?.oilGasFieldName,
              endOfLifeDate: windProject?.oilGasProjectDetail?.endOfLifeDate,
              owner: windProject?.oilGasProjectDetail?.owners
                ?.map((o: GenericType) => o.companyName)
                .join(","),
            }
          : undefined,
        equipmentContracts: {
          turbineContracts: equipmentContracts?.turbineContracts
            ?.filter(
              (c: GenericType) =>
                c?.contract?.contract?.contract?.equipmentTypes?.[0]?.type ===
                  "Turbine" &&
                c?.contract?.contract?.contract?.contractWorkType?.type ===
                  "Manufacturing",
            )
            ?.map((c: GenericType) => ({
              title: `${
                c?.contract?.contract?.contract?.awardDate
                  ? moment(c?.contract?.contract?.contract?.awardDate).format(
                      "YYYY-MM-DD",
                    )
                  : ""
              } - ${c?.contract?.contract?.contract?.contractedCompanies
                ?.map((o: GenericType) => o?.contractee?.name)
                .join(", ")}`,
              awardDate: c?.contract?.contract?.contract?.awardDate,
              contractedCompanies:
                c?.contract?.contract?.contract?.contractedCompanies
                  ?.map((o: GenericType) => o?.contractee?.name)
                  .join(", "),
              numOrdered: c?.contract?.numOrdered,
              turbineModel: c?.contract?.turbineModel?.name,
              contractStatus:
                c?.contract?.contract?.contract?.contractStatus?.status,
            }))
            .sort((a: GenericType, b: GenericType) =>
              moment(a.title).diff(moment(b.title)),
            ),
          foundationContracts: equipmentContracts?.foundationContracts
            ?.map((c: GenericType) => ({
              title: `${
                c?.contract?.contract?.contract?.awardDate
                  ? moment(c?.contract?.contract?.contract?.awardDate).format(
                      "YYYY-MM-DD",
                    )
                  : ""
              } - ${c?.contract?.contract?.contract?.contractedCompanies
                ?.map((o: GenericType) => o?.contractee?.name)
                .join(", ")}`,
              awardDate: c?.contract?.contract?.contract?.awardDate,
              contractedCompanies:
                c?.contract?.contract?.contract?.contractedCompanies
                  ?.map((o: GenericType) => o?.contractee?.name)
                  .join(", "),
              numOrdered: c?.contract?.numOrdered,
              foundationType:
                c?.contract?.contract?.contract?.equipmentTypes?.[0].type,
              contractStatus:
                c?.contract?.contract?.contract?.contractStatus?.status,
              factory: c?.factory?.map((f: GenericType) => f.name).join(", "),
              foundationMass:
                c?.contract?.fixedFoundationMetrics?.maxFoundationMassMt,
              foundationLength:
                c?.contract?.fixedFoundationMetrics?.maxFoundationDepthM,
              foundationDiameter:
                c?.contract?.fixedFoundationMetrics?.maxFoundationDiameterM,
              foundationThickness:
                c?.contract?.fixedFoundationMetrics?.maxFoundationThicknessM,
            }))
            .sort((a: GenericType, b: GenericType) =>
              moment(a.title).diff(moment(b.title)),
            ),
          cableContracts: equipmentContracts?.cableContracts
            ?.map((c: GenericType) => ({
              title: `${
                c?.contract?.contract?.contract?.awardDate
                  ? moment(c?.contract?.contract?.contract?.awardDate).format(
                      "YYYY-MM-DD",
                    )
                  : ""
              } - ${c?.contract?.contract?.contract?.contractedCompanies
                ?.map((o: GenericType) => o?.contractee?.name)
                .join(", ")}`,
              awardDate: c?.contract?.contract?.contract?.awardDate,
              contractedCompanies:
                c?.contract?.contract?.contract?.contractedCompanies
                  ?.map((o: GenericType) => o?.contractee?.name)
                  .join(", "),
              cableType: c?.contract?.cable?.cableType?.type,
              factory: c?.factory?.map((f: GenericType) => f.name).join(", "),
              voltage: c?.contract?.cable?.cableVoltageKv,
              outputCurrent: c?.contract?.cable?.currentType?.type,
              numOrdered: c?.contract?.numOrdered,
              cableLength: c?.contract?.cable?.cableLengthKm,
            }))
            .sort((a: GenericType, b: GenericType) =>
              moment(a.title).diff(moment(b.title)),
            ),
          substationContracts: equipmentContracts?.substationContracts
            ?.map((c: GenericType) => ({
              title: `${
                c?.contract?.contract?.contract?.awardDate
                  ? moment(c?.contract?.contract?.contract?.awardDate).format(
                      "YYYY-MM-DD",
                    )
                  : ""
              } - ${c?.contract?.contract?.contract?.contractedCompanies
                ?.map((o: GenericType) => o?.contractee?.name)
                .join(", ")}`,
              awardDate: c?.contract?.contract?.contract?.awardDate,
              contractedCompanies:
                c?.contract?.contract?.contract?.contractedCompanies
                  ?.map((o: GenericType) => o?.contractee?.name)
                  .join(", "),
              substation: c?.contract?.substation?.name,
              components: c?.contract?.contract?.contract?.equipmentTypes
                ?.map((e: GenericType) => e.type)
                .sort()
                .join(", "),
              contractStatus:
                c?.contract?.contract?.contract?.contractStatus?.status,
              factory: c?.factory?.map((f: GenericType) => f.name).join(", "),
              outputCurrent: c?.contract?.outputCurrent?.type,
              voltageIn: c?.contract?.voltage?.arrayCableVoltage,
              voltageOut: c?.contract?.voltage?.exportCableVoltage,
            }))
            ?.sort((a: GenericType, b: GenericType) =>
              moment(a.title).diff(moment(b.title)),
            ),
          towerContracts: equipmentContracts?.towerContracts
            ?.map((c: GenericType) => ({
              title: `${
                c?.contract?.contract?.contract?.awardDate
                  ? moment(c?.contract?.contract?.contract?.awardDate).format(
                      "YYYY-MM-DD",
                    )
                  : ""
              } - ${c?.contract?.contract?.contract?.contractedCompanies
                ?.map((o: GenericType) => o?.contractee?.name)
                .join(", ")}`,
              awardDate: c?.contract?.contract?.contract?.awardDate,
              contractedCompanies:
                c?.contract?.contract?.contract?.contractedCompanies
                  ?.map((o: GenericType) => o?.contractee?.name)
                  .join(", "),
              contractStatus:
                c?.contract?.contract?.contract?.contractStatus?.status,
              numOrdered: c?.contract?.numOrdered,
            }))
            ?.sort((a: GenericType, b: GenericType) =>
              moment(a.title).diff(moment(b.title)),
            ),
          floatingContracts: equipmentContracts?.floatingContracts
            ?.map((c: GenericType) => ({
              title: `${
                c?.contract?.contract?.contract?.awardDate
                  ? moment(c?.contract?.contract?.contract?.awardDate).format(
                      "YYYY-MM-DD",
                    )
                  : ""
              } - ${c?.contract?.contract?.contract?.contractedCompanies
                ?.map((o: GenericType) => o?.contractee?.name)
                .join(", ")}`,
              awardDate: c?.contract?.contract?.contract?.awardDate,
              contractedCompanies:
                c?.contract?.contract?.contract?.contractedCompanies
                  ?.map((o: GenericType) => o?.contractee?.name)
                  .join(", "),
              contractStatus:
                c?.contract?.contract?.contract?.contractStatus?.status,
              factory: c?.factory?.map((f: GenericType) => f.name).join(", "),
              contractType:
                c?.contract?.contract?.contract?.contractWorkType?.type,
              technologyType: c?.contract?.floatingTechnology?.technology,
            }))
            .sort((a: GenericType, b: GenericType) =>
              moment(a.title).diff(moment(b.title)),
            ),
        },
        vesselContracts: {
          installationContract:
            vesselContractsList
              ?.filter((c: GenericType) => {
                return c?.equipmentTypes?.some((equipmentType: IdNamePair) =>
                  installationIds.includes(equipmentType.id),
                );
              })
              ?.map((c: GenericType) => ({
                title: `${
                  c?.awardDate ? moment(c?.awardDate).format("YYYY-MM-DD") : ""
                } - ${c?.vesselInfo?.vesselName}`,
                awardDate: c?.awardDate,
                vesselName: c?.vesselInfo?.vesselName,
                vesselOwner: c?.vesselInfo?.owners
                  ?.map((v: GenericType) => v?.companyName)
                  .join(", "),
                startDate: c?.startDate,
                endDate: c?.endDate,
                workType: c?.workType?.type,
                foundationType: c?.windProject?.fixedFoundationType
                  ?.map((t: GenericType) => t?.type)
                  ?.join(", "),
                turbineModel: c?.windProject?.turbineModels?.[0]?.name,
              })) || null,
          floatingInstallation: vesselContractsList
            ?.filter((c: GenericType) => {
              return c?.equipmentTypes?.some((equipmentType: IdNamePair) =>
                floatingIds.includes(equipmentType.id),
              );
            })
            ?.map((c: GenericType) => ({
              title: `${
                c?.awardDate ? moment(c?.awardDate).format("YYYY-MM-DD") : ""
              } - ${c?.vesselInfo?.vesselName}`,
              awardDate: c?.awardDate,
              vesselName: c?.vesselInfo?.vesselName,
              vesselOwner: c?.vesselInfo?.owners
                ?.map((v: GenericType) => v?.companyName)
                .join(", "),
              startDate: c?.startDate,
              endDate: c?.endDate,
              floatingTechnology: c?.windProject?.cableType?.type,
            })),
          cableLayContract: vesselContractsList
            ?.filter((c: GenericType) => {
              return c?.equipmentTypes?.some((equipmentType: IdNamePair) =>
                cableIds.includes(equipmentType.id),
              );
            })
            ?.map((c: GenericType) => ({
              title: `${
                c?.awardDate ? moment(c?.awardDate).format("YYYY-MM-DD") : ""
              } - ${c?.vesselInfo?.vesselName}`,
              awardDate: c?.awardDate,
              vesselName: c?.vesselInfo?.vesselName,
              vesselOwner: c?.vesselInfo?.owners
                ?.map((v: GenericType) => v?.companyName)
                .join(", "),
              startDate: c?.startDate,
              endDate: c?.endDate,
              cableType: c?.windProject?.cableType?.type,
            })),
        },
        windProjectContracts: windProjectContracts?.sort(
          (a: GenericType, b: GenericType) => {
            if (moment(a.startDate).year() === moment(b.startDate).year()) {
              return a.contractedCompanies[0]?.contractee?.name?.toLowerCase() >
                b.contractedCompanies[0]?.contractee?.name?.toLowerCase()
                ? 1
                : -1;
            }
            return moment(a.startDate).year() > moment(b.startDate).year()
              ? -1
              : 1;
          },
        ),
      };
      setData(result);
    } catch (err) {
      console.log(">>", err);
      onClose();
      toast.error(`${MESSAGE.Project}: ${getErrorMessage(err)}`);
    }
  };

  useEffect(() => {
    setData(null);
    if (id) {
      fetchProjectData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const equipmentProperties = {
    turbines: false,
    turbinesData: {},
    foundations: false,
    foundationsData: {},
    cables: false,
    cablesData: {},
    substations: false,
    substationsData: {},
    towers: false,
    towersData: {},
    floating: false,
    floatingData: {},
  };

  const vesselProperties = {
    cable: false,
    cableData: {},
    floating: false,
    floatingData: {},
    installation: false,
    installationData: {},
    maintenance: false,
    maintenanceData: {},
  };

  const PPAProperties: GenericType = {
    idPPA: 0,
    dataSet: [],
  };

  let hasPPAContract = false;

  if (
    data?.windProjectContracts?.length > 0 &&
    data?.windProjectContracts?.some((contract: GenericType) => {
      return contract.ppaDetails;
    })
  ) {
    hasPPAContract = true;
    data?.windProjectContracts.forEach((contract: GenericType) => {
      if (contract.ppaDetails) {
        PPAProperties.idPPA = data?.windProjectContracts[0]?.windProjectIds[0];
        PPAProperties.dataSet.push({
          buyer: contract.contractedCompanies[0]?.contractee?.name,
          seller: contract.contractedCompanies[0]?.contractor?.name,
          sizeMW: contract.ppaDetails.sizeMW,
          duration: contract.ppaDetails.duration,
          type: contract.contractType?.type,
          startYear: contract.startDate
            ? moment(contract.startDate).year()
            : "",
          endYear: contract.endDate ? moment(contract.endDate).year() : "",
          notes: contract.ppaDetails.notes,
          idPPA: contract.id,
          title: `${
            contract?.startDate ? moment(contract.startDate).year() : ""
          } - ${contract.contractedCompanies[0]?.contractee?.name}`,
        });
      }
    });
  }

  let hasEqContract = false;

  if (data?.equipmentContracts?.turbineContracts?.length) {
    equipmentProperties.turbines = true;
    hasEqContract = true;
    equipmentProperties.turbinesData =
      data?.equipmentContracts?.turbineContracts;
  }

  if (data?.equipmentContracts?.foundationContracts?.length) {
    equipmentProperties.foundations = true;
    hasEqContract = true;
    equipmentProperties.foundationsData =
      data?.equipmentContracts?.foundationContracts;
  }

  if (data?.equipmentContracts?.cableContracts) {
    equipmentProperties.cables = true;
    hasEqContract = true;
    equipmentProperties.cablesData = data?.equipmentContracts?.cableContracts;
  }

  if (data?.equipmentContracts?.substationContracts?.length) {
    equipmentProperties.substations = true;
    hasEqContract = true;
    equipmentProperties.substationsData =
      data?.equipmentContracts?.substationContracts;
  }

  if (data?.equipmentContracts?.towerContracts?.length) {
    equipmentProperties.towers = true;
    hasEqContract = true;
    equipmentProperties.towersData = data?.equipmentContracts?.towerContracts;
  }

  if (data?.equipmentContracts?.floatingContracts?.length) {
    equipmentProperties.floating = true;
    hasEqContract = true;
    equipmentProperties.floatingData =
      data?.equipmentContracts?.floatingContracts;
  }

  let hasVeContract = false;

  if (data?.vesselContracts?.cableLayContract?.length) {
    vesselProperties.cable = true;
    hasVeContract = true;
    vesselProperties.cableData = data?.vesselContracts?.cableLayContract;
  }

  if (data?.vesselContracts?.floatingInstallationContract?.length) {
    vesselProperties.floating = true;
    hasVeContract = true;
    vesselProperties.floatingData =
      data?.vesselContracts?.floatingInstallationContract;
  }

  if (data?.vesselContracts?.installationContract?.length) {
    vesselProperties.installation = true;
    hasVeContract = true;
    vesselProperties.installationData =
      data?.vesselContracts?.installationContract;
  }

  return (
    <Modal
      sx={classes.modalContainer}
      open={!!id}
      size="sm"
      key={id}
      title={data?.keyInfo?.project || "Project"}
      handleClose={onClose}
    >
      {data ? (
        <Box sx={classes.contentBox}>
          <Paragraph
            title="Key Info"
            properties={{
              project: { key: "Project", publicItem: true },
              country: { key: "Country", publicItem: true },
              developers: { key: "Developers", publicItem: true },
              projectStatus: { key: "Project status", publicItem: true },
              projectType: { key: "Project type", publicItem: true },
              capacity: { key: "Capacity", unit: "MW", publicItem: true },
              numberOfTurbines: { key: "Number of turbines" },
              operationDate: { key: "Operation Date", type: "date" },
              decommissioningDate: {
                key: "Decommissioning date",
                type: "date",
              },
              foundationInstallationDate: {
                key: "Foundation installation date",
                type: "date",
              },
              turbineInstallationDate: {
                key: "Turbine installation date",
                type: "date",
              },
            }}
            data={data.keyInfo}
          />
          <Divider sx={classes.divider} />
          <Paragraph
            title="Project details"
            properties={{
              leaseRound: { key: "Lease round" },
              fid: { key: "FID" },
              maxWaterDepth: { key: "Maximum water depth", unit: "m" },
              intertidal: { key: "Intertidal" },
              freshWaterLocation: { key: "Fresh water location" },
              distanceShore: { key: "Distance to shore", unit: "km" },
              area: { key: "Area", unit: "km2" },
            }}
            initExpand={false}
            data={data.projectDetails}
          />
          {hasPPAContract && (
            <>
              <Divider sx={classes.divider} />
              <Paragraph
                title="PPAs"
                properties={PPAProperties}
                initExpand={false}
                data={data}
              />
            </>
          )}
          <Divider sx={classes.divider} />
          <Paragraph
            title="Equipment"
            properties={{
              turbineSupplier: { key: "Turbine supplier" },
              turbineModel: { key: "Turbine model" },
              turbineNominalPower: {
                key: "Turbine nominal power",
                unit: "MW",
              },
              tipHeight: { key: "Tip height", unit: "m" },
              hubHeight: { key: "Hub height", unit: "m" },
              rotorDiameter: { key: "Rotor diameter", unit: "m" },
              foundationSupplier: { key: "Foundation supplier" },
              foundationType: { key: "Foundation type" },
            }}
            initExpand={false}
            data={data.equipment}
          />
          <Divider sx={classes.divider} />
          <Paragraph
            title="Vessels"
            properties={{
              mainPortName: { key: "Main port name" },
              turbineInstaller: { key: "Turbine installer" },
              foundationInstaller: { key: "Foundation installer" },
              cableInstaller: { key: "Cable installer" },
            }}
            initExpand={false}
            data={data.vessels}
          />
          {data.floating ? (
            <>
              <Divider sx={classes.divider} />
              <Paragraph
                title="Floating"
                properties={{
                  floatingMaterial: { key: "Floating material" },
                  floatingTechnology: { key: "Floating technology" },
                  floaterType: { key: "Floater type" },
                  anchorMaterial: { key: "Anchor material" },
                  totalNumberAnchors: { key: "Total number of anchors" },
                  mooringLineType: { key: "Mooring line type" },
                  totalNumberMooringLines: {
                    key: "Total number of mooring lines",
                  },
                  mooringLineLength: {
                    key: "Mooring line length",
                    unit: "m",
                  },
                  displacementPerUnit: {
                    key: "Displacement per unit",
                    unit: "m3",
                  },
                  turbinesPerFloatingUnit: {
                    key: "Turbines per floating unit",
                  },
                  draught: { key: "Draught", unit: "m" },
                  hullMassPerUnit: { key: "Hull mass per unit", unit: "t" },
                  ballastMassPerUnit: {
                    key: "Ballast mass per unit",
                    unit: "t",
                  },
                }}
                initExpand={false}
                data={data.floating}
              />
            </>
          ) : null}

          {data.hydrogen ? (
            <>
              <Divider sx={classes.divider} />
              <Paragraph
                title="Hydrogen"
                properties={{
                  hydrogenProject: { key: "Hydrogen project" },
                  hydrogenDeveloper: { key: "Hydrogen developer" },
                  hydrogenTechnology: { key: "Hydrogen technology" },
                  dateOnline: { key: "Date online", type: "date" },
                  electrolysisMethod: { key: "Electrolysis method" },
                  electrolyserSupplier: { key: "Electrolysis supplier" },
                  storageMethod: { key: "Storage method" },
                  distributionMethod: { key: "Distribution method" },
                }}
                initExpand={false}
                data={data.hydrogen}
              />
            </>
          ) : null}
          {data.oilAndGas ? (
            <>
              <Divider sx={classes.divider} />
              <Paragraph
                title="Oil and gas"
                properties={{
                  oilAndGasFields: { key: "Oil and gas fields" },
                  endOfLifeDate: { key: "End of life" },
                  owner: { key: "Oil and gas field ownership" },
                }}
                initExpand={false}
                data={data.oilAndGas}
              />
            </>
          ) : null}
          <Divider sx={classes.divider} />
          <Button
            sx={{ cursor: "pointer" }}
            component={Link}
            to={`${ROUTES_CONFIG.ActivityVisualisation.baseUrl}/project/${id}`}
            variant="text"
          >
            Display activity visualisation
          </Button>
          {data.leaseRoundInformation ? (
            <>
              <Divider sx={classes.divider} />
              <Paragraph
                title="Lease round"
                properties={{
                  leaseRoundName: { key: "Lease round name" },
                  leaseRoundTimeLine: {
                    key: "Timeline",
                    index: "leaseRoundId",
                    handleClick: onLeaseRoundClick,
                  },
                  leaseRoundStatus: { key: "Lease round status" },
                  leaseAwardDate: {
                    key: "Scheduled award date",
                    type: "date",
                  },
                  leaseAreas: { key: "Lease areas" },
                }}
                data={data.leaseRoundInformation}
              />
            </>
          ) : null}

          {hasEqContract && (
            <>
              <Divider sx={classes.divider} />
              <Paragraph
                title="Equipment contracts"
                properties={equipmentProperties}
                initExpand={false}
                data={data}
              />
            </>
          )}
          {hasVeContract && (
            <>
              <Divider sx={classes.divider} />
              <Paragraph
                title="Vessel contracts"
                properties={vesselProperties}
                initExpand={false}
                data={data}
              />
            </>
          )}
        </Box>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
