/* eslint-disable indent */
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { Box, Typography } from "@esgian/esgianui";
import { Eventcalendar, MbscEventcalendarView, Popup } from "@mobiscroll/react";
import moment from "moment";

import { calculateDurationByWeeks } from "../../helper/chart";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import { setSelectedProjectId } from "../../store/slice/common";
import {
  GenericType,
  Resource,
  VesselContractChartTabEnum,
  VesselStatusEnum,
} from "../../types";
import { Legend } from "../Legend";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";

type Prop = {
  data: GenericType[] | null;
  legends: GenericType;
  height?: string;
  tab?: VesselContractChartTabEnum;
  projectDrawer?: boolean;
  vesselDrawer?: boolean;
  vesselDrawerMethod?: (e: number) => void;
};

export const VesselTimeline: FC<Prop> = ({
  data,
  height,
  legends,
  projectDrawer = false,
  vesselDrawer = false,
  vesselDrawerMethod,
  tab,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [selectedItem, setSelectedItem] = useState<GenericType | null>(null);
  const [currentDateRange, setCurrentDateRange] = useState<{
    startDate: string;
    endDate: string;
  } | null>(null);

  const themeMode = useSelector(getThemeMode);
  const timerRef = useRef<GenericType | null>(null);

  const myView = useMemo<MbscEventcalendarView>(
    () => ({
      timeline: {
        size: 4,
        resolutionHorizontal: "quarter",
        type: "year",
      },
    }),
    [],
  );

  const myResources = useMemo(() => {
    if (!currentDateRange || !data) return [];

    const { startDate, endDate } = currentDateRange;
    const seenIds = new Set();

    return data
      .filter((item) =>
        item.contract.some(
          (c: GenericType) =>
            moment(c.startDate).isSameOrBefore(endDate) &&
            moment(c.endDate).isSameOrAfter(startDate),
        ),
      )
      .map((item) => ({
        id: item.id,
        name: item.name,
      }))
      .filter((item) => {
        if (seenIds.has(item.id)) {
          return false;
        }
        seenIds.add(item.id);
        return true;
      });
  }, [data, currentDateRange]);

  const myEvents = useMemo(() => {
    const list: GenericType[] = [];
    data?.forEach((item: GenericType) => {
      item.contract.forEach((c: GenericType) => {
        list.push({
          resource: item.id,
          start: moment(c.startDate),
          end: moment(c.endDate),
          duration: calculateDurationByWeeks(c.startDate, c.endDate),
          ...c,
        });
      });
    });
    return list;
  }, [data]);

  const handleEventHoverIn = useCallback((args: GenericType) => {
    setSelectedItem(args.event);
    setAnchor(args.domEvent.target);
    setOpen(true);
  }, []);

  const handleEventHoverOut = useCallback(() => {
    setOpen(false);
  }, []);

  const handleEventClick = useCallback(() => {
    setOpen(true);
  }, []);

  const onMouseEnter = useCallback(() => {
    if (timerRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clearTimeout(timerRef.current as any);
    }
  }, []);

  const renderMyResource = useCallback(
    (resource: Resource) => {
      return (
        <Box
          sx={{
            color: "#0E9EFF",
            cursor: "pointer",
          }}
          onClick={() => {
            if (projectDrawer) {
              dispatch(setSelectedProjectId(resource.id));
            }
            if (vesselDrawer && vesselDrawerMethod) {
              vesselDrawerMethod(resource.id);
            }
          }}
        >
          <Typography>{resource.name}</Typography>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectDrawer, vesselDrawer, vesselDrawerMethod],
  );

  const handleDateRangeChange = (event: {
    firstDay: string | number | Date;
    lastDay: string | number | Date;
  }) => {
    const startDate = moment(event.firstDay).format("YYYY-MM-DD");
    const endDate = moment(event.lastDay).format("YYYY-MM-DD");
    setCurrentDateRange({ startDate, endDate });
  };

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Legend
          radius="1px"
          title={VesselStatusEnum.Foundation}
          color="rgb(145, 51, 200)"
          sx={{ ml: 1 }}
        />
        {Object.keys(legends)?.includes(
          VesselStatusEnum.FoundationEstimated,
        ) ? (
          <Legend
            radius="1px"
            title="Foundation estimated"
            hatchedColor={legends[VesselStatusEnum.FoundationEstimated]}
            sx={{ ml: 1 }}
          />
        ) : null}
        <Legend
          radius="1px"
          title={VesselStatusEnum.Oms}
          color="rgb(214, 236, 11)"
          sx={{ ml: 1 }}
        />
        {Object.keys(legends)?.includes(VesselStatusEnum.OMSEstimated) ? (
          <Legend
            radius="1px"
            title="OMS estimated"
            hatchedColor={legends[VesselStatusEnum.OMSEstimated]}
            sx={{ ml: 1 }}
          />
        ) : null}
        <Legend
          radius="1px"
          title={VesselStatusEnum.Turbine}
          color="rgb(82, 202, 238)"
          sx={{ ml: 1 }}
        />
        {Object.keys(legends)?.includes(VesselStatusEnum.TurbineEstimated) ? (
          <Legend
            radius="1px"
            title="Turbine estimated"
            hatchedColor={legends[VesselStatusEnum.TurbineEstimated]}
            sx={{ ml: 1 }}
          />
        ) : null}
        <Legend
          radius="1px"
          title={VesselStatusEnum.VesselUnderConstruction}
          color="rgb(0, 139, 106)"
          sx={{ ml: 1 }}
        />
        {Object.keys(legends)?.includes(
          VesselStatusEnum.VesselUnderConstructionEstimated,
        ) ? (
          <Legend
            radius="1px"
            title="Vessel under construction estimated"
            hatchedColor={
              legends[VesselStatusEnum.VesselUnderConstructionEstimated]
            }
            sx={{ ml: 1 }}
          />
        ) : null}
        <Legend
          radius="1px"
          title={VesselStatusEnum.CableLay}
          color="rgb(255, 105, 180)"
          sx={{ ml: 1 }}
        />
        {Object.keys(legends)?.includes(VesselStatusEnum.CablelayEstimated) ? (
          <Legend
            radius="1px"
            title="Cable lay estimated"
            hatchedColor={legends[VesselStatusEnum.CablelayEstimated]}
            sx={{ ml: 1 }}
          />
        ) : null}
      </Box>
      <Box sx={{ overflow: "scroll", height: height || "calc(100vh - 250px)" }}>
        <Eventcalendar
          theme="material"
          themeVariant={themeMode}
          clickToCreate={false}
          dragToCreate={false}
          dragToMove={false}
          dragToResize={false}
          eventDelete={true}
          view={myView}
          data={myEvents}
          resources={myResources}
          showEventTooltip={false}
          renderResource={
            projectDrawer || vesselDrawer ? renderMyResource : undefined
          }
          onEventHoverIn={handleEventHoverIn}
          onEventHoverOut={handleEventHoverOut}
          onEventClick={handleEventClick}
          onPageLoaded={handleDateRangeChange}
          onPageChange={handleDateRangeChange}
          renderScheduleEvent={(item: GenericType) => {
            return (
              <Box
                key={item.id}
                sx={{
                  background:
                    item.original.contractStatus === "Provisional" ||
                    item.original.contractStatus === "Speculative" ||
                    item.original?.estimated
                      ? `repeating-linear-gradient(
                        45deg,
                        white 0 10px,
                        ${legends[item.original.status]} 10px 15px)
                      `
                      : legends[item.original.status],
                  height: 24,
                  mt: 1,
                  borderRadius: "4px",
                  width: "100%",
                }}
              />
            );
          }}
        />
        <Popup
          display="anchored"
          isOpen={isOpen}
          anchor={anchor || undefined}
          touchUi={false}
          showOverlay={false}
          contentPadding={false}
          closeOnOverlayClick={false}
          themeVariant={themeMode}
          sx={{
            minWidth: 300,
            maxWidth: 500,
          }}
        >
          {selectedItem ? (
            <Box onMouseEnter={onMouseEnter} sx={{ p: 1 }}>
              {selectedItem?.windFarm ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1, // Equivalent to spacing={1}
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: 12 }}>
                    {tab === VesselContractChartTabEnum.VesselOverview
                      ? "Wind farm:"
                      : "Vessel:"}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 14, fontWeight: "bold" }}
                  >
                    {tab === VesselContractChartTabEnum.ContractOverview
                      ? selectedItem.vessel
                      : selectedItem.windFarm}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 14, fontWeight: "bold" }}
                  >
                    {selectedItem.contractStatus}
                  </Typography>
                </Box>
              ) : null}
              {selectedItem?.country ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1, // Equivalent to spacing={1}
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: 12 }}>
                    Country:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 14, fontWeight: "bold" }}
                  >
                    {selectedItem.country}
                  </Typography>
                </Box>
              ) : null}
              {selectedItem?.start ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1, // Equivalent to spacing={1}
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: 12 }}>
                    Start date:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 14, fontWeight: "bold" }}
                  >
                    {moment(selectedItem?.start).format("DD-MM-YYYY")}
                  </Typography>
                </Box>
              ) : null}
              {selectedItem?.end ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1, // Equivalent to spacing={1}
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: 12 }}>
                    End date:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 14, fontWeight: "bold" }}
                  >
                    {moment(selectedItem?.end).format("DD-MM-YYYY")}
                  </Typography>
                </Box>
              ) : null}
              {selectedItem?.duration ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1, // Equivalent to spacing={1}
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: 12 }}>
                    Duration:
                  </Typography>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "bold", mr: 1 }}
                    >
                      {selectedItem?.duration.weeks} weeks
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "bold" }}
                    >
                      {selectedItem?.duration.days} days
                    </Typography>
                  </Box>
                </Box>
              ) : null}
              {selectedItem?.contractAwardedDate ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1, // Equivalent to spacing={1}
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: 12 }}>
                    Contract award date:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 14, fontWeight: "bold" }}
                  >
                    {moment(selectedItem?.contractAwardedDate).format(
                      "DD-MM-YYYY",
                    )}
                  </Typography>
                </Box>
              ) : null}
              {selectedItem?.turbineModel ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1, // Equivalent to spacing={1}
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: 12 }}>
                    Turbine model:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 14, fontWeight: "bold" }}
                  >
                    {selectedItem?.turbineModel}
                  </Typography>
                </Box>
              ) : null}
              {selectedItem?.foundationType ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1, // Equivalent to spacing={1}
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: 12 }}>
                    Foundation type:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 14, fontWeight: "bold" }}
                  >
                    {selectedItem?.foundationType}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Popup>
      </Box>
    </Box>
  );
};
