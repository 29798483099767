/* eslint-disable prettier/prettier */
import moment from "moment";

import {
  Country,
  GenericType,
  IdNamePair,
  IdTypePair,
  TIMELINE_STATUS,
} from "../types";

const hexToRgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
};

const rgbToHex = ({ r, g, b }: GenericType) => {
  return "#" + [r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("");
};

export const generateUniqueColors = (
  colorArray: string[],
  count: number,
  darkeningFactor: number = 0.5,
) => {
  const mixedColors = [];
  const step =
    colorArray.length > 1
      ? (colorArray.length - 1) / Math.max(1, count - 1)
      : 0;

  for (let i = 0; i < count; i++) {
    const index = step * i;
    const lowerIndex = Math.floor(index);
    const upperIndex = Math.ceil(index);
    const ratio = index - lowerIndex;

    const lowerColor = hexToRgb(colorArray[lowerIndex % colorArray.length]);
    const upperColor = hexToRgb(colorArray[upperIndex % colorArray.length]);

    const mixedColor = {
      r: Math.round(
        (lowerColor.r + (upperColor.r - lowerColor.r) * ratio) *
          darkeningFactor,
      ),
      g: Math.round(
        (lowerColor.g + (upperColor.g - lowerColor.g) * ratio) *
          darkeningFactor,
      ),
      b: Math.round(
        (lowerColor.b + (upperColor.b - lowerColor.b) * ratio) *
          darkeningFactor,
      ),
    };

    mixedColors.push(rgbToHex(mixedColor));
  }

  return mixedColors;
};

export const calculateDurationByWeeks = (start: string, end: string) => {
  const startDate = moment(start);
  const endDate = moment(end);
  const totalDays = endDate.diff(startDate, "days");

  const weeks = Math.floor(totalDays / 7);
  const days = totalDays % 7;

  return { weeks, days };
};

export const calculateDurationByDaysAndHours = (start: string, end: string) => {
  const startDate = moment(start);
  const endDate = moment(end);
  const totalHours = endDate.diff(startDate, "hours");

  const days = Math.floor(totalHours / 24);
  const hours = totalHours % 24;

  return { days, hours };
};

export const diffInYears = (date1: string, date2: string): number => {
  const startDate = moment(date1).clone();
  const endDate = moment(date2).clone();
  const diffDays = endDate.diff(startDate, "days");

  return diffDays / 365.25;
};

export const calculateAverageLeadTime = (contracts: GenericType) => {
  let totalLeadTime = 0;

  contracts.forEach((contract: GenericType) => {
    const awardDate = contract.awardDate;
    const operationDate = contract?.windProject?.operationDate;
    totalLeadTime += diffInYears(awardDate, operationDate);
  });

  const averageLeadTime =
    contracts.length > 0 ? totalLeadTime / contracts.length : 0;
  return averageLeadTime;
};

export const calculateTrailing12MonthsAverage = (
  date: string,
  allContracts: GenericType,
) => {
  const endDate = moment(date, "MMM YYYY").endOf("month").format("YYYY-MM-DD");
  const startDate = moment(date, "MMM YYYY")
    .subtract(1, "year")
    .startOf("month")
    .format("YYYY-MM-DD");
  let totalLeadTime = 0.0;
  let count = 0;

  for (const [contractDate, contracts] of Object.entries(allContracts)) {
    const contractEndDate = moment(contractDate, "MMM YYYY").format(
      "YYYY-MM-DD",
    );
    if (
      moment(contractEndDate).isBetween(
        moment(startDate),
        moment(endDate),
        null,
        "[]",
      )
    ) {
      if (contracts.length === 0) {
        continue;
      }
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      contracts.forEach((contract: GenericType) => {
        const awardDate = contract.awardDate;
        const endedDate = contract.windProject?.operationDate;
        if (awardDate && endedDate) {
          totalLeadTime += diffInYears(awardDate, endedDate);
        }
        count++;
      });
    }
  }

  return count > 0 ? totalLeadTime / count : 0;
};

export const convertToDesiredArrayFormat = (
  groupedContracts: GenericType,
  countries: Country[],
  popupItems: string[] = ["turbineModel"],
) => {
  const result = [];
  const fiveYearsAgo = moment().subtract(5, "years");

  for (const [date, contracts] of Object.entries(groupedContracts)) {
    const averageLeadTime = calculateAverageLeadTime(contracts);
    const trailing12Months = calculateTrailing12MonthsAverage(
      date,
      groupedContracts,
    );
    const averageLeadTimeProjects = contracts.map((contract: GenericType) => {
      return {
        windFarmName: contract?.windProject?.name,
        country: contract?.windProject?.countryId
          ? countries.find((c) => c.id === contract.windProject.countryId)?.name
          : "",
        turbineModel: popupItems.includes("turbineModel")
          ? (contract?.windProject?.turbineModels
              ?.map((t: IdNamePair) => t.name)
              .join(", ") ?? "")
          : null,
        equipmentType: popupItems.includes("equipmentType")
          ? (contract?.equipmentTypes
              ?.map((e: IdTypePair) => e.type)
              ?.join(", ") ?? "")
          : null,
        vesselName: popupItems.includes("vesselName")
          ? (contract?.vesselName ?? "")
          : null,
      };
    });

    result.push({
      date,
      numberOfContracts: contracts.length,
      averageLeadTime,
      trailing12Months,
      averageLeadTimeProjects,
    });
  }
  return result
    .filter((item) => moment(item.date, "MMM YYYY", true).isAfter(fiveYearsAgo))
    .sort((a, b) =>
      moment(a.date, "MMM YYYY").diff(moment(b.date, "MMM YYYY")),
    );
};

export const convertToYearsAndMonths = (
  totalLeadTime: number,
): { years: number; months: number } => {
  let years = Math.floor(totalLeadTime);
  let months = Math.round((totalLeadTime - years) * 12);
  if (months === 12) {
    years++;
    months = 0;
  }
  return { years, months };
};

export const findContractStatus = (contract: GenericType): string => {
  const equipmentTypes =
    contract?.contract?.equipmentTypeIds ||
    contract?.contract?.equipmentTypes?.map((c: GenericType) => c.id);
  const workType = contract?.contract?.contractWorkType?.type;
  for (const type of TIMELINE_STATUS) {
    if (type.workType === workType) {
      // Check if any of the equipment types match
      if (
        type.equipmentTypes.length === 0 ||
        equipmentTypes.some((equipmentType: number) =>
          type.equipmentTypes.includes(equipmentType),
        )
      ) {
        return type.name;
      }
    }
  }

  return "Unknown";
};

export const generateCategories = (
  startDate: moment.MomentInput,
  endDate: moment.MomentInput,
  periodType: string,
) => {
  const intervals = [];
  const start = moment(startDate);
  const end = moment(endDate);

  switch (periodType) {
    case "hour":
      while (start.isBefore(end)) {
        intervals.push(start.format("YYYY-MM-DD HH:00"));
        start.add(1, "hour");
      }
      break;
    case "day":
      while (start.isBefore(end)) {
        intervals.push(start.format("YYYY-MM-DD"));
        start.add(1, "day");
      }
      break;
    case "week":
      while (start.isBefore(end)) {
        intervals.push(start.format("YYYY-[W]WW"));
        start.add(1, "week");
      }
      break;
    case "month":
      while (start.isBefore(end)) {
        intervals.push(start.format("YYYY-MM"));
        start.add(1, "month");
      }
      break;
    case "year":
      while (start.isBefore(end)) {
        intervals.push(start.format("YYYY"));
        start.add(1, "year");
      }
      break;
    default:
      break;
  }

  return intervals;
};
