import { Chart, Plugin } from "chart.js";

/**
 * Configuration options for a Y-axis title.
 */
interface AxisTitleOptions {
  text: string;
  fontSize?: string;
  fontColor?: string;
  YPosition: "top" | "bottom"; // Position can only be 'top' or 'bottom'
  XPosition: "left" | "right"; // Position can only be 'left' or 'right'
}

/**
 * AxisTitlePlugin is a Chart.js plugin that renders titles on Y-axes.
 */
export class AxisTitlePlugin {
  name: string;

  optionsList: AxisTitleOptions[];

  /**
   * @param optionsList - List of configuration options for the Y-axis titles.
   */
  constructor(optionsList: AxisTitleOptions[] = []) {
    this.name = "yAxisTitle";

    // Default options if none are provided
    this.optionsList =
      optionsList.length > 0
        ? optionsList
        : [
            {
              text: "y-axis title",
              fontSize: "12px",
              fontColor: "red",
              YPosition: "bottom",
              XPosition: "left",
            },
            {
              text: "y-axis2 title",
              fontSize: "12px",
              fontColor: "red",
              YPosition: "top",
              XPosition: "right",
            },
          ];
  }

  /**
   * Returns the plugin configuration object required by Chart.js.
   * @returns Chart.js plugin configuration object.
   */
  getPlugin(): Plugin {
    return {
      id: "yAxisTitle",
      afterDraw: (chart) => this.afterDraw(chart),
      beforeLayout: (chart) => this.beforeLayout(chart),
    };
  }

  /**
   * Adjusts the layout padding based on Y-axis titles.
   * @param chart - The Chart.js chart instance.
   */
  beforeLayout(chart: Chart) {
    const { ctx } = chart;
    let paddingAdjustment = 0;

    // Ensure layout and padding are initialized with optional chaining
    const layout = chart.options.layout || {};
    const layoutPadding = (layout.padding || {}) as Partial<{
      top: number;
      right: number;
      bottom: number;
      left: number;
    }>;

    this.optionsList.forEach((options) => {
      const titleWidth = ctx.measureText(options.text).width;
      if (titleWidth > paddingAdjustment) {
        paddingAdjustment = titleWidth;
      }

      let bottom = 0;
      if (options.XPosition === "right") {
        if (options.YPosition === "bottom") {
          bottom = 10;
        }

        // Update layout padding safely with optional chaining
        chart.options.layout = {
          ...layout,
          padding: {
            ...layoutPadding,
            right: (layoutPadding?.right ?? 0) + paddingAdjustment + 15,
            bottom: bottom,
          },
        };
      }
    });
  }

  /**
   * Renders the Y-axis titles on the chart after it is drawn.
   * @param chart - The Chart.js chart instance.
   */
  afterDraw(chart: Chart) {
    const {
      ctx,
      chartArea: { top, bottom },
      scales,
    } = chart;
    if (Object.keys(scales).length === 0) return;

    const isHorizontal = chart.options.indexAxis === "y";

    this.optionsList.forEach((options) => {
      const { text, fontSize, fontColor, YPosition, XPosition } = options;
      ctx.save();
      ctx.font = `${fontSize || "12px"} Noto Sans`;
      ctx.fillStyle = fontColor || "#000";

      const titleWidth = ctx.measureText(text).width;

      let xPosition: number;
      let scale = scales.y;
      let yPosition: number;
      // Determine X-position
      if (XPosition === "left") {
        scale = scales.x || scale;
        if (YPosition === "top") {
          xPosition = scale.left - titleWidth;
        } else {
          xPosition = scale.left - titleWidth / 2;
        }
      } else if (XPosition === "right") {
        scale = scales.x || scale;
        xPosition = scale.right + (isHorizontal ? 15 : 10);
      } else {
        return; // Invalid XPosition, skip rendering
      }

      // Determine Y-position
      if (YPosition === "top") {
        yPosition = top - 15;
      } else if (YPosition === "bottom") {
        yPosition = bottom + (isHorizontal ? 20 : 5);
      } else {
        return; // Invalid YPosition, skip rendering
      }

      // Translate and draw text
      ctx.translate(xPosition, yPosition);
      ctx.fillText(text, 0, 0);
      ctx.restore();
    });
  }
}
