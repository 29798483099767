import { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  ProgressBar,
  Skeleton,
  Stack,
  Typography,
} from "@esgian/esgianui";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import { api } from "../../api/api";
import {
  hoursToADate,
  pointsBetweenDates,
} from "../../helper/datesCalculations";
import { safelyCallApi } from "../../helper/requestWrapper";
import { useSelector } from "../../hooks/use-selector";
import { usePowerGenQueries } from "../../hooks/useQueries/usePowerGenQueries";
import {
  getPowerOutputTimePeriod,
  getPowerOutputWindProject,
} from "../../store/selector/powerOutput";
import { GenericType } from "../../types";
import TextWithTooltipIcon from "../TextWithTooltipIcon";

type KPI = {
  name: string;
  sort: number;
  hint?: string | null;
  value: number | string | null;
};

const renderKpiValue = (
  { name, hint, value }: KPI,
  loading = false,
  expandOpen = false,
) => {
  return (
    <Stack
      direction="column"
      sx={{ textAlign: "center", alignItems: "center" }}
    >
      {hint ? (
        <TextWithTooltipIcon
          iconSize="16px"
          gap="4px"
          label={<Typography variant="body2">{name}</Typography>}
          tooltipText={hint}
        />
      ) : (
        <Typography variant="body2">{name}</Typography>
      )}
      <Typography sx={{ height: expandOpen ? "8vh" : "100%" }} variant="h6">
        {loading ? <Skeleton width="10em" /> : value}
      </Typography>
    </Stack>
  );
};

const kpiDefaults = [
  {
    name: "Operation date",
    sort: 1,
    hint: "COD of the project. Note: The wind farm might have started power production before the official COD date",
    value: null,
  },
  {
    name: "Country",
    sort: 2,
    hint: null,
    value: null,
  },
  {
    name: "Installed capacity",
    sort: 3,
    hint: "Initial installed capacity at COD",
    value: null,
  },
  {
    name: "Number of turbines",
    sort: 4,
    hint: "Initial number of turbines at COD",
    value: null,
  },
  {
    name: "Power Generation",
    sort: 5,
    hint: "Total net power generation during the selected timeframe",
    value: null,
  },

  {
    name: "Capacity factor",
    sort: 6,
    hint: "Net hourly-average capacity factor during the selected timeframe, based on initial installed capacity",
    value: null,
  },

  {
    name: "Min. capacity factor",
    sort: 11,
    hint: "Net minimum hourly-average capacity factor during the selected timeframe, based on initial installed capacity",
    value: null,
  },
  {
    name: "Max. capacity factor",
    sort: 12,
    hint: "Net maximum hourly-average capacity factor during the selected timeframe, based on initial installed capacity",
    value: null,
  },
];

export const PowerOutputKeyBoxProject: FC = () => {
  const selectedProject = useSelector(getPowerOutputWindProject);
  const selectedTimePeriod = useSelector(getPowerOutputTimePeriod);
  const { powerDataFullProjectQuery } = usePowerGenQueries();
  const [expandDevelopers, setExpandDevelopers] = useState(false);
  const [expandTurbine, setExpandTurbine] = useState(false);

  useEffect(() => {
    setExpandDevelopers(false);
    setExpandTurbine(false);
  }, [selectedProject]);

  const farmDetailsQuery = useQuery({
    queryKey: ["farmDetails", selectedProject],
    enabled: !!selectedProject,
    placeholderData: null,
    queryFn: () => {
      if (!selectedProject) return null;
      return safelyCallApi(
        api.project.getProjectListInfo({
          windProjectIds: [selectedProject.id],
        }),
      )
        .then(({ status, data: resp }) => {
          if (status !== 200) {
            return null;
          }
          const data = resp[0];
          return {
            turbineData: data.windProject.turbineModels,
            turbineIds:
              data.windProject.turbineModels?.map(
                ({ id }: GenericType) => id,
              ) ?? [],
            developers:
              data.developers?.map(
                ({ companyName }: GenericType) => companyName,
              ) ?? [],
          };
        })
        .catch(() => {
          return null;
        });
    },
  });

  const turbineDetailsQuery = useQuery({
    queryKey: ["turbineDetails", farmDetailsQuery.data?.turbineIds],
    enabled: !!farmDetailsQuery.data?.turbineIds?.length,
    placeholderData: [],
    queryFn: ({ queryKey }) => {
      return safelyCallApi(
        api.turbine.getTurbines({
          ids: queryKey[1],
        }),
      )
        .then(({ status, data: resp }) => {
          if (status !== 200 || !farmDetailsQuery.data) {
            return [];
          }

          return farmDetailsQuery.data.turbineData.map((item: GenericType) => {
            const details = resp.find(
              ({ turbineModel: { id } }: GenericType) => id === item.id,
            );
            return {
              name: item.name,
              power: item.ratedPowerMw,
              suppliers: details.turbineModel.suppliers
                .map(({ companyName }: GenericType) => companyName)
                .join(", "),
            };
          });
        })
        .catch(() => {
          return [];
        });
    },
  });
  const hourlyDataQuery = useQuery({
    queryKey: [
      "powerGenChartData",
      {
        selectedProject,
      },
    ],
    enabled: true,
    placeholderData: {},
    queryFn: () => {
      const ids: number[] = [];
      if (selectedProject) {
        ids.push(selectedProject.id);
      }

      return safelyCallApi(
        api.powerOutputAggregated.getPowerOutputAggregated({
          projectIds: ids,
          includeHistorical: true,
          aggregationLevel: "hour",
          historyRollup: "hour",
        }),
      )
        .then(({ status, data }: GenericType) => {
          if (status !== 200) return {};
          let res: GenericType = {};
          ids.forEach((val) => {
            if (!val) return;
            const { projectId, historicalData } = data[val];
            if (!projectId || !historicalData) return;
            res = historicalData;
          });
          return res;
        })
        .catch(() => {
          return {};
        });
    },
  });

  const data = useMemo(() => {
    if (
      !powerDataFullProjectQuery.data ||
      !selectedProject ||
      !selectedTimePeriod ||
      !farmDetailsQuery.data ||
      !hourlyDataQuery.data
    ) {
      return { dataCoverage: 0, kpis: [...kpiDefaults], loading: true };
    }
    const queryData = powerDataFullProjectQuery.data as Record<
      string,
      GenericType
    >;

    const projectData = queryData[selectedProject.id];
    const hourData = hourlyDataQuery.data;
    const {
      installedCapacity,
      firstRecordTime,
      operationDate,
      countryName,
      numTurbines,
      accumulatedData: { capacityFactor, generationOutput },
    } = projectData;

    const numberOfRecords = pointsBetweenDates(
      selectedTimePeriod.name,
      hourData,
      firstRecordTime,
    );
    const numberOfHours = hoursToADate(
      selectedTimePeriod.name,
      "hours",
      firstRecordTime,
    );
    let dataCoverage = 0;
    if (numberOfRecords && numberOfHours > 0) {
      dataCoverage = (numberOfRecords * 100) / numberOfHours;
    } else {
      dataCoverage = 0;
    }
    const powerGeneration =
      generationOutput[selectedTimePeriod?.responseKey as string].value;
    const currentCapacity =
      capacityFactor[selectedTimePeriod?.responseKey as string];

    const capacityFactorPercent = currentCapacity.value;
    const minCapFactorPercent = currentCapacity.min;
    const maxCapFactorPercent = currentCapacity.max;
    const kpis: KPI[] = [...kpiDefaults];

    kpis[0].value = moment(operationDate).format("yyyy-MM-DD");
    kpis[1].value = countryName;
    kpis[2].value = `${installedCapacity * 1000} MW`;
    kpis[3].value = numTurbines;
    kpis[4].value = `${Math.round(powerGeneration)} GWh`;
    if (Number.isNaN(capacityFactorPercent)) {
      kpis[5].value = "-";
    } else {
      kpis[5].value = `${Math.round(capacityFactorPercent)}%`;
    }
    kpis[6].value = `${Math.round(minCapFactorPercent)}%`;
    kpis[7].value = `${Math.round(maxCapFactorPercent)}%`;

    return {
      loading: false,
      dataCoverage: dataCoverage,
      kpis: kpis,
    };
  }, [
    farmDetailsQuery.data,
    powerDataFullProjectQuery.data,
    hourlyDataQuery.data,
    selectedProject,
    selectedTimePeriod,
  ]);

  const getDeveloperText = () => {
    if (!farmDetailsQuery.data) return "";
    let text = farmDetailsQuery.data?.developers[0];
    let sx = {};
    let onClick = () => {};
    if (farmDetailsQuery.data?.developers.length > 1) {
      sx = { cursor: "pointer" };
      onClick = () => setExpandDevelopers(true);
      text = `${text} +${farmDetailsQuery.data?.developers.length - 1}`;
    }
    return (
      <Typography sx={sx} variant="h6" onClick={onClick}>
        {text}
      </Typography>
    );
  };

  const getTurbineText = (key: string, unit: string) => {
    if (!turbineDetailsQuery.data) return "";
    let text = `${turbineDetailsQuery.data[0][key]} ${unit}`;
    let sx = {};
    let onClick = () => {};
    if (turbineDetailsQuery.data?.length > 1) {
      sx = { cursor: "pointer" };
      onClick = () => setExpandTurbine(true);
      text = `${text} +${turbineDetailsQuery.data?.length - 1}`;
    }
    return (
      <Typography sx={sx} variant="h6" onClick={onClick}>
        {text}
      </Typography>
    );
  };

  const turbineDisplayList = useMemo(() => {
    return [
      { key: "name", title: "Turbine model(s)", unit: "" },
      { key: "suppliers", title: "Turbine supplier(s)", unit: "" },
      { key: "power", title: "Turbine power(s)", unit: "MW" },
    ];
  }, []);

  return (
    <Box
      sx={{
        background: ({ palette }: GenericType) => palette.background.paper,
        p: 2,
        borderRadius: 4,
        color: ({ palette }: GenericType) => palette.text.primary,
      }}
    >
      <Stack direction="column" spacing={2}>
        <Typography variant="h6">Key figures</Typography>
        <Stack direction="column" spacing={1}>
          <TextWithTooltipIcon
            iconSize="16px"
            gap="4px"
            label={<Typography variant="body2">Power data coverage</Typography>}
            tooltipText="The horizontal line illustrates the number of hours with registered power generation data for the wind farm compared to the total number of hours during the selected timeframe"
          />
          <ProgressBar
            variant={data.loading ? "indeterminate" : "determinate"}
            value={Math.round(data.dataCoverage)}
          />
        </Stack>

        <Stack direction="row">
          <Box
            sx={{
              display: "grid",
              borderRight: ({ palette }: GenericType) =>
                `1px dashed ${palette.tooltip.divider}`,
              width: "66.66%",
              gridTemplateColumns: "repeat(auto-fill, minmax(245px, 1fr))", // Minimum width for an item
              gap: "40px 16px", // Spacing between items
              textAlign: "center",
            }}
          >
            {[...data.kpis]
              .filter(({ sort }) => [1, 2, 3, 4].includes(sort))
              .map((kpi, index) => (
                <Box key={index}>{renderKpiValue(kpi, data.loading)}</Box>
              ))}
            {turbineDisplayList.map(({ key, title, unit }) => (
              <Box key={`${key}`}>
                <Stack
                  direction="column"
                  sx={{ textAlign: "center", alignItems: "center" }}
                >
                  <Typography variant="body2">{title}</Typography>
                  {turbineDetailsQuery.isFetching && (
                    <Typography variant="h6">
                      <Skeleton width="10em" />
                    </Typography>
                  )}
                  <Box sx={{ maxHeight: "8vh", overflow: "auto" }}>
                    {!expandTurbine &&
                      turbineDetailsQuery.data.length &&
                      getTurbineText(key, unit)}
                    {expandTurbine &&
                      turbineDetailsQuery.data?.map(
                        (turbine: GenericType, i: number) => (
                          <Typography
                            key={`${turbine[key]}-${i}`}
                            variant="h6"
                            alignSelf="center"
                            sx={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {`${turbine[key]} ${unit}`}
                          </Typography>
                        ),
                      )}
                  </Box>
                </Stack>
              </Box>
            ))}

            <Box>
              <Stack
                direction="column"
                sx={{
                  textAlign: "center",
                  width: "calc(100% - 15px)",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">Developers</Typography>
                {farmDetailsQuery.isFetching && (
                  <Typography variant="h6">
                    <Skeleton width="10em" />
                  </Typography>
                )}
                <Box sx={{ maxHeight: "8vh", overflow: "auto" }}>
                  {!expandDevelopers &&
                    farmDetailsQuery.data &&
                    getDeveloperText()}

                  {expandDevelopers &&
                    farmDetailsQuery.data?.developers.map(
                      (developer: string, i: number) => (
                        <Typography
                          key={`${developer}-${i}`}
                          variant="h6"
                          alignSelf="center"
                          sx={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {developer}
                        </Typography>
                      ),
                    )}
                </Box>
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              width: "33.32%",
              gridTemplateColumns: "repeat(auto-fill, minmax(245px, 1fr))", // Minimum width for an item
              gap: "40px 16px", // Spacing between items
              textAlign: "center",
            }}
          >
            {[...data.kpis]
              .filter(({ sort }) => [5, 6, 11, 12].includes(sort))
              .map((kpi, index) => (
                <Box key={index}>
                  {renderKpiValue(
                    kpi,
                    data.loading,
                    [2, 3].includes(index) &&
                      (expandTurbine || expandDevelopers),
                  )}
                </Box>
              ))}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
